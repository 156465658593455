import { createFileRoute, Outlet } from '@tanstack/react-router';
import { Divider, Flex } from '@mantine/core';
import { useInboxItems, useInboxStore, useOrganisationStore } from 'fe/queries';
import { Products } from 'shared/db';
import { PageLoader } from 'fe/feature/layout';
import { ConversationsList } from 'fe/feature/inbox';
import { Helmet } from 'react-helmet-async';

export const Route = createFileRoute('/leads')({
  component: Index
});

function Index() {
  const { organisation, isLoading: isLoadingOrganisation } =
    useOrganisationStore();
  const {
    filterCampaignType,
    filterCampaigns,
    filterLeadStatus,
    filterSearchTerm
  } = useInboxStore((state) => ({
    filterLeadStatus: state.filterLeadStatus,
    filterCampaigns: state.filterCampaigns,
    filterCampaignType: state.filterCampaignType,
    filterSearchTerm: state.filterSearchTerm
  }));

  const {
    data: pages,
    fetchNextPage,
    isFetching: isLoadingInboxItems
  } = useInboxItems(
    organisation?.id,
    filterSearchTerm,
    filterLeadStatus,
    filterCampaigns,
    filterCampaignType as Products
  );

  const data = pages?.pages.map((group) => group).flat() || [];

  if (isLoadingOrganisation) return <PageLoader />;

  return (
    <Flex w="100%">
      <Helmet>
        <title>Leads - Hey Rafi</title>
      </Helmet>

      <ConversationsList
        items={data || []}
        fetchNextPage={fetchNextPage}
        isLoading={isLoadingInboxItems && data.length === 0}
      />

      <Divider orientation="vertical" />

      <Outlet />
    </Flex>
  );
}
