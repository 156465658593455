import { useDocumentTitle } from '@mantine/hooks';
import { createFileRoute } from '@tanstack/react-router';
import { WorkflowManagement } from 'fe/feature/organisation-settings';

export const Route = createFileRoute('/settings/_layout/workflows')({
  component: RouteComponent
});

function RouteComponent() {
  useDocumentTitle('Emails & Workflows - Settings - Hey Rafi');
  return <WorkflowManagement />;
}
