import { useDocumentTitle } from '@mantine/hooks';
import { createFileRoute } from '@tanstack/react-router';
import { useCampaign } from 'fe/queries';
import Home from './../../App';

export const Route = createFileRoute('/campaigns/$campaignId')({
  component: RouteComponent
});

function RouteComponent() {
  const { campaignId } = Route.useParams();
  const { data: campaign } = useCampaign(campaignId);
  useDocumentTitle(
    campaign ? `${campaign.name} - Hey Rafi` : 'Campaign - Hey Rafi'
  );
  return <Home />;
}
