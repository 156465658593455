import {
  Box,
  TextInput,
  Grid,
  Avatar,
  Flex,
  Button,
  InputBase,
  FileButton,
  ActionIcon,
} from '@mantine/core';
import {
  useCreateUserProfile,
  useOrganisationStore,
  useUpdateUserProfile,
  useUploadProfilePicture,
  useUser,
  useUserProfile,
} from 'fe/queries';
import { useForm, zodResolver } from '@mantine/form';
import { z } from 'zod';
import { IMaskInput } from 'react-imask';
import { IconX } from '@tabler/icons-react';
import { notifications } from '@mantine/notifications';
import { zodPhoneNumberNullable } from 'shared/validators';
import { formatPhoneNumberToNational } from 'shared/formatter';
interface ProfileFormProps {
  onSuccess?: () => void;
}

export function ProfileForm({ onSuccess }: ProfileFormProps) {
  const { organisation } = useOrganisationStore();
  const { data: user } = useUser();
  const { data: profileData } = useUserProfile();
  const profile = profileData?.profile;
  const { mutateAsync: createProfile, isPending: isCreating } =
    useCreateUserProfile();
  const { mutateAsync: updateProfile, isPending: isUpdating } =
    useUpdateUserProfile();
  const { mutateAsync: uploadPicture, isPending: isUploading } =
    useUploadProfilePicture();

  const schema = z.object({
    first_name: z.string().min(1),
    last_name: z.string().min(1),
    phone: zodPhoneNumberNullable,
  });

  const form = useForm<{
    first_name: string;
    last_name: string;
    phone: string | undefined;
    picture: File | undefined | null | string;
  }>({
    initialValues: {
      first_name: profile?.first_name || '',
      last_name: profile?.last_name || '',
      phone: profile?.phone
        ? formatPhoneNumberToNational(profile.phone)
        : undefined,
      picture: profile?.picture,
    },
    validate: zodResolver(schema),
  });

  const isSubmitting = isCreating || isUpdating || isUploading;

  const onSubmit = async (values: typeof form.values) => {
    if (!user) return;
    let picturePath: undefined | null | string;
    if (values.picture === null) {
      picturePath = null;
    } else if (values.picture && typeof values.picture !== 'string') {
      picturePath = await uploadPicture({
        userId: user.id,
        file: values.picture,
      });
    } else {
      picturePath = undefined;
    }
    const formattedValues = {
      ...values,
      picture: picturePath,
    };
    if (profile) {
      await updateProfile(formattedValues);
      notifications.show({
        title: 'Profile updated',
        message: 'Profile updated successfully',
      });
    } else {
      await createProfile(formattedValues);
      notifications.show({
        title: 'Profile created',
        message: 'Profile created successfully',
      });
    }
    onSuccess?.();
  };

  const pictureUrl =
    form.values.picture && typeof form.values.picture !== 'string'
      ? URL.createObjectURL(form.values.picture)
      : form.values.picture;

  return (
    <Box>
      <form onSubmit={form.onSubmit(onSubmit)}>
        <Flex direction="column" gap="xs">
          <Grid>
            <Grid.Col span={6}>
              <Flex gap="xs" align="flex-end">
                <Avatar
                  src={pictureUrl}
                  alt="no image here"
                  color="indigo"
                  size="xl"
                />

                <Flex direction="column-reverse" gap="xs">
                  <FileButton
                    onChange={(file) => form.setFieldValue('picture', file)}
                    accept="image/png,image/jpeg"
                  >
                    {(props) => (
                      <Button variant="outline" size="xs" {...props}>
                        Upload picture
                      </Button>
                    )}
                  </FileButton>

                  {form.values.picture && (
                    <ActionIcon
                      variant="outline"
                      onClick={() => form.setFieldValue('picture', null)}
                    >
                      <IconX size="1.125rem" />
                    </ActionIcon>
                  )}
                </Flex>
              </Flex>
            </Grid.Col>
            <Grid.Col
              span={6}
              style={{ display: 'flex', alignItems: 'flex-end' }}
            >
              <TextInput
                label="Company"
                placeholder="John"
                disabled
                defaultValue={organisation?.name}
                style={{ flex: 1 }}
              />
            </Grid.Col>
            <Grid.Col span={6}>
              <TextInput
                required
                label="First Name"
                {...form.getInputProps('first_name')}
              />
            </Grid.Col>
            <Grid.Col span={6}>
              <TextInput
                required
                label="Last Name"
                {...form.getInputProps('last_name')}
              />
            </Grid.Col>

            <Grid.Col span={6}>
              <TextInput
                label="Email"
                placeholder="John"
                disabled
                defaultValue={user?.email}
              />
            </Grid.Col>
            <Grid.Col span={6}>
              <InputBase
                label="Phone"
                component={IMaskInput}
                mask="(000) 000-0000"
                placeholder="(000) 000-0000"
                {...form.getInputProps('phone')}
                onAccept={(value) => {
                  form.setFieldValue('phone', value);
                }}
              />
            </Grid.Col>
          </Grid>

          <Button type="submit" fullWidth mt="md" loading={isSubmitting}>
            Save
          </Button>
        </Flex>
      </form>
    </Box>
  );
}
