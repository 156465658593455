import {
  Box,
  Button,
  Paper,
  Title,
  Text,
  Flex,
  ThemeIcon,
} from '@mantine/core';
import { IconCheck, IconQuestionMark } from '@tabler/icons-react';
import {
  useIsPhoneEnabled,
  useOrganisationStore,
  useSetUpPhoneAccount,
} from 'fe/queries';
import { formatPhoneNumberToNational } from 'shared/formatter';

interface PhoneSettingsProps {
  organization_id: number;
}

export function PhoneSettings({ organization_id }: PhoneSettingsProps) {
  const { organisation } = useOrganisationStore();
  const { mutateAsync: setUpPhoneAccount, isPending } = useSetUpPhoneAccount();
  const { data: isEnabled, isLoading } = useIsPhoneEnabled({
    organization_id,
  });

  return (
    <Box>
      {!isEnabled || isLoading ? (
        <Paper p="xl">
          <Flex gap="lg" justify="space-between" align="center">
            <Title order={4}>
              Please enable phone calling to see more settings
            </Title>

            <Button
              loading={isPending}
              onClick={() =>
                setUpPhoneAccount({
                  organization_id,
                })
              }
            >
              Enable Phone Calling
            </Button>
          </Flex>
        </Paper>
      ) : (
        <Paper p="xl" bg="green.0" withBorder mt="xl">
          <Flex gap="lg" align="center">
            <ThemeIcon radius="xl" color="gray">
              <IconCheck />
            </ThemeIcon>
            <Text>
              Phone calling has been enabled.{' '}
              {organisation?.phone_number
                ? `Your organization's phone number is ${formatPhoneNumberToNational(organisation?.phone_number)}.`
                : ''}
            </Text>
          </Flex>
        </Paper>
      )}

      <Paper p="xl" bg="blue.0" withBorder mt="xl">
        <Flex gap="lg" align="center">
          <ThemeIcon radius="xl" color="gray">
            <IconQuestionMark />
          </ThemeIcon>
          <Text>To enable SMS, please get in touch with our support team.</Text>
        </Flex>
      </Paper>
    </Box>
  );
}
