import { SupabaseClient } from '@supabase/supabase-js';
import { Property } from './properties.types';

export interface PropertyComp {
  id: number;
  street_address: string;
  zip_code: number;
  comp_street_address: string;
  comp_zip_code: number;
  negative_feedback: string;
  confidence_score: number;
}

export type PropertyCompView = Property & {
  property_comps: (PropertyComp & {
    proximity: number;
  })[];
};

export async function savePropertyComps(
  supabase: SupabaseClient,
  property: {
    street_address: string;
    zip_code: number;
  },
  comparables: Property[],
) {
  const { error: errorDelete } = await supabase
    .from('property_comps')
    .delete()
    .eq('street_address', property.street_address)
    .eq('zip_code', property.zip_code);

  if (errorDelete) {
    console.log('error: ', errorDelete);
    throw new Error("Couldn't delete property comps");
  }

  const compsToInsert = comparables.map((comp) => ({
    street_address: property.street_address,
    zip_code: property.zip_code,
    comp_street_address: comp.street_address,
    comp_zip_code: comp.zip_code,
  }));
  const { error } = await supabase
    .from('property_comps')
    .upsert(compsToInsert)
    .select();

  if (error) {
    console.log('error: ', error);
    throw new Error("Couldn't save property comps");
  }
}

export async function fetchPropertyComps(
  supabase: SupabaseClient,
  street_address: string,
  zip_code: number,
): Promise<PropertyCompView[]> {
  const { data, error } = await supabase
    .from('properties')
    .select(
      '*, property_comps:property_comps_view!property_comps_comp_street_address_comp_zip_code_fkey!inner(*)',
    )
    .eq('property_comps_view.street_address', street_address)
    .eq('property_comps_view.zip_code', zip_code);

  if (error) {
    console.log('error: ', error);
    throw new Error("Couldn't fetch property comps");
  }

  return data;
}

export async function updatePropertyComp(
  supabase: SupabaseClient,
  comp: PropertyComp,
): Promise<PropertyComp> {
  const { data, error } = await supabase
    .from('property_comps')
    .update({
      ...comp,
      proximity: undefined,
    })
    .eq('id', comp.id)
    .select()
    .single();

  if (error) {
    throw new Error("Couldn't update property comp");
  }

  return data;
}
