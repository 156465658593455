import React, { useEffect } from 'react';
import {
  Divider,
  Flex,
  ScrollArea,
  Image,
  Loader,
  Skeleton,
  Text,
  Box,
} from '@mantine/core';
import {
  Products,
  useInboxStore,
  useLeadInbox,
  useLeadProfilePage,
  useLeadProfileStore,
  useMarkAllMessagesAsSeen,
  useOrganisationStore,
} from 'fe/queries';
import { ConversationDetailSidenav } from '../ConversationDetailSidenav';
import { ConvoItem } from './../Conversation/ConvoItem/ConvoItem';
import { scrollToBottom } from 'shared/formatter';
import { ConversationActions } from './../Conversation/ConversationActions';
import { MessageForm } from './../Conversation/MessageForm';
import { SmsForm } from './../Conversation/SmsForm/SmsForm';

interface ProfileProps {
  leadId: number;
}

export function Profile({ leadId }: ProfileProps) {
  const { data: lead, isLoading } = useLeadProfilePage({
    lead_id: Number(leadId),
  });
  const { organisation } = useOrganisationStore();
  const {
    markAllMessagesAsSeen,
    filterCampaignType,
    filterCampaigns,
    filterLeadStatus,
    filterSearchTerm,
  } = useInboxStore((state) => ({
    markAllMessagesAsSeen: state.markAllMessagesAsSeen,
    filterLeadStatus: state.filterLeadStatus,
    filterCampaigns: state.filterCampaigns,
    filterCampaignType: state.filterCampaignType,
    filterSearchTerm: state.filterSearchTerm,
  }));
  const { displayEmailForm, displaySmsForm, toggleEmailForm } =
    useLeadProfileStore((state) => ({
      displayEmailForm: state.displayEmailForm,
      displaySmsForm: state.displaySmsForm,
      toggleEmailForm: state.toggleEmailForm,
    }));
  const { mutateAsync: markAsSeen } = useMarkAllMessagesAsSeen(
    organisation?.id || 0,
    filterSearchTerm,
    filterLeadStatus,
    filterCampaigns,
    filterCampaignType as Products,
  );

  const { data: activities, isLoading: isLoadingInbox } = useLeadInbox(leadId);

  useEffect(() => {
    markAllMessagesAsSeen();
    markAsSeen({ lead_id: leadId });
    setTimeout(() => scrollToBottom('chat-scroll'), 100);
  }, [activities, displayEmailForm, displaySmsForm]);

  if (isLoading || !lead) {
    return (
      <Flex
        style={{ flex: 1 }}
        align="center"
        justify="center"
        direction="column"
        gap="md"
      >
        <Loader color="gray" />
      </Flex>
    );
  }

  return (
    <Flex style={{ flex: 1 }}>
      <Flex style={{ flex: 1 }} direction="column">
        <Flex
          w="100%"
          direction="column"
          align="flex-start"
          justify="flex-start"
          py="md"
          px="xl"
          gap="md"
        >
          <Flex justify="space-between" w="100%" align="center">
            <Flex justify="flex-start" gap="sm" align="center">
              <Image src={lead.img} radius="xs" width={42} height={42} />
              <div>
                <Text fw={600} fz="md">
                  {lead.address.street_address}
                </Text>
                <Text c="dimmed" fz="sm">
                  {lead.address.city}, {lead.address.state}
                  {lead.address.zip_code}
                </Text>
              </div>
            </Flex>
          </Flex>

          <ConversationActions
            leadId={leadId}
            url={lead.property_info.url}
            toggleReply={toggleEmailForm}
          />
        </Flex>

        <div style={{ flex: 1, position: 'relative' }}>
          <ScrollArea
            id="chat-scroll"
            style={{ position: 'absolute', height: '100%', width: '100%' }}
            p="xl"
            pt="0"
          >
            <Flex direction="column" style={{ flex: 1 }} gap="xl">
              {isLoadingInbox ? (
                <>
                  <Skeleton key="loader-1" height={200} mb="xs" />
                  <Skeleton key="loader-2" height={200} mb="xs" />
                </>
              ) : (
                (activities || []).map((activity, index) => (
                  <React.Fragment key={index}>
                    <ConvoItem key={`${index}-content`} item={activity} />
                  </React.Fragment>
                ))
              )}
            </Flex>

            <Box mt="xl">
              {displaySmsForm ? (
                <SmsForm lead_id={leadId} />
              ) : (
                <MessageForm
                  leadId={leadId}
                  url={lead.property_info.url}
                  toggleReply={toggleEmailForm}
                  displayReply={displayEmailForm}
                />
              )}
            </Box>
          </ScrollArea>
        </div>
      </Flex>

      <Divider orientation="vertical" />

      <div>
        <ConversationDetailSidenav leadId={leadId} />
      </div>
    </Flex>
  );
}
