import { create } from 'zustand';

interface State {
  displayEmailForm: boolean;
  toggleEmailForm: () => void;
  displaySmsForm: boolean;
  toggleSmsForm: (phone?: string) => void;
  // sms form
  phoneRecipient: string | null;
  setPhoneRecipient: (phone: string) => void;
}

export const useLeadProfileStore = create<State>()((set, get) => {
  return {
    displayEmailForm: false,
    toggleEmailForm: () => set({ displayEmailForm: !get().displayEmailForm }),
    displaySmsForm: false,
    toggleSmsForm: (phone) => {
      const newValue = phone ? phone : null;
      set({ displaySmsForm: !get().displaySmsForm, phoneRecipient: newValue });
    },
    phoneRecipient: null,
    setPhoneRecipient: (phone) => set({ phoneRecipient: phone }),
  };
});
