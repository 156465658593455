import { createFileRoute } from '@tanstack/react-router';
import { RepairLevelSettings } from 'fe/feature/organisation-settings';

export const Route = createFileRoute('/settings/_layout/repairs')({
  component: RouteComponent
});

function RouteComponent() {
  return <RepairLevelSettings />;
}
