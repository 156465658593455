import { useDocumentTitle } from '@mantine/hooks';
import { createFileRoute } from '@tanstack/react-router';
import { PrivacyPolicy } from 'fe/feature/auth';

export const Route = createFileRoute('/privacy-policy')({
  component: Index
});

function Index() {
  useDocumentTitle('Privacy Policy - Hey Rafi');
  return <PrivacyPolicy />;
}
