import {
  CampaignListing,
  CampaignOfferRecommendationWithProperty,
} from 'shared/db';
import { useQueryClient } from '@tanstack/react-query';
import { removeSuggestionsInboxItem } from '../inbox/utils';
import { trpc } from '../setup';

export const useLeadContract = trpc.lead.getContract.useQuery;

export const useLeadProfilePage = trpc.lead.getProfilePage.useQuery;

export const useRejectLead = () => {
  const queryClient = useQueryClient();
  return trpc.organization.campaigns.rejectOffer.useMutation({
    onSuccess: (data, params) => {
      queryClient.setQueriesData<CampaignOfferRecommendationWithProperty[]>(
        { queryKey: ['recommendations', params.campaign_id] },
        (previous) => [
          ...(previous || []).filter(
            (recommendation) =>
              recommendation.street_address !== params.street_address &&
              recommendation.zip_code !== params.zip_code,
          ),
        ],
      );

      queryClient.setQueriesData<CampaignListing[]>(
        { queryKey: ['listings'] },
        (previous) =>
          (previous || []).filter(
            (offer) =>
              offer.street_address !== params.street_address ||
              offer.zip_code !== params.zip_code,
          ),
      );
    },
  });
};

export const useUpdateLead = (lead_id: number) => {
  const removeSuggestions = removeSuggestionsInboxItem();
  const utils = trpc.useUtils();
  return trpc.inbox.updateLeadStatus.useMutation({
    onSuccess: (data, params) => {
      utils.lead.getProfilePage.setData({ lead_id }, (previous) => {
        if (!previous) return previous;
        return {
          ...previous,
          status: params.status,
        };
      });
      utils.inbox.getLeadSuggestions.setData({ lead_id }, (previous) => {
        if (!previous) return previous;
        const newSuggestions = previous.filter(
          (suggestion) => suggestion.type !== 'status_change',
        );
        if (newSuggestions.length === 0) {
          removeSuggestions(lead_id);
        }
        return newSuggestions;
      });
      utils.inbox.activity.getLeadActivity.setData({ lead_id }, (previous) => {
        return previous ? [...previous, data] : [data];
      });
    },
  });
};
