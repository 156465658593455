import { useDocumentTitle } from '@mantine/hooks';
import { createFileRoute } from '@tanstack/react-router';
import { SearchPage } from '../../pages/offer/SearchPage/SearchPage';

export const Route = createFileRoute('/offer/')({
  component: Offer
});

function Offer() {
  useDocumentTitle(`Search - Hey Rafi`);
  return <SearchPage />;
}
