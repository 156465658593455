import { Flex, Container, Loader } from '@mantine/core';
import { Layout } from '../index';

export function PageLoader({ noMenu = false }) {
  return (
    <Layout noScrolling noMenu={noMenu} hideProfileGuard noHeader>
      <Container size="25rem" pt="xl">
        <Flex my="xl" justify="center" w="80%" align="center" h="80%">
          <Loader size="xl" />
        </Flex>
      </Container>
    </Layout>
  );
}
