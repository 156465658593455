import { useDocumentTitle } from '@mantine/hooks';
import { createFileRoute } from '@tanstack/react-router';
import { TermsOfService } from 'fe/feature/auth';

export const Route = createFileRoute('/terms-of-service')({
  component: Index
});

function Index() {
  useDocumentTitle('Terms of Service - Hey Rafi');
  return <TermsOfService />;
}
