import { z } from 'zod';
import { formatPhoneNumberToE164, isValidNumber } from 'shared/formatter';

export const zodPhoneNumber = z
  .string()
  .refine(
    (val) => {
      return isValidNumber(val);
    },
    {
      message: 'Invalid phone number',
    },
  )
  .optional();

export const zodPhoneNumberNullable = z
  .string()
  .nullable()
  .refine(
    (val) => {
      if (!val) return true;
      const formattedNumber = formatPhoneNumberToE164(val);
      if (!formattedNumber) return false;
      return isValidNumber(formattedNumber);
    },
    {
      message: 'Invalid phone number',
    },
  )
  .optional();

export const zodUrlWithoutProtocol = z.string().refine(
  (value) => {
    const regex = /^(?:[a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(?:\/.*)?$/; // Matches domain.tld and optional path
    return regex.test(value);
  },
  {
    message:
      "Invalid URL. It should look like 'example.com' or 'example.com/path'.",
  },
);
