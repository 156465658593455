import React, { useEffect, useRef, useState } from 'react';
import { useEditor, EditorContent } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import Underline from '@tiptap/extension-underline';
import Image from '@tiptap/extension-image';
import { Box, Flex, ActionIcon, Select, ScrollArea } from '@mantine/core';
import {
  IconBold,
  IconItalic,
  IconUnderline,
  IconAlignLeft,
  IconAlignCenter,
  IconAlignRight,
  IconListNumbers,
  IconList,
  IconArrowBackUp,
  IconArrowForwardUp,
  IconPhoto,
  IconTable,
} from '@tabler/icons-react';
import './StyledEditor.module.scss';
import TextAlign from '@tiptap/extension-text-align';
import TextStyle from '@tiptap/extension-text-style';
import FontFamily from '@tiptap/extension-font-family';
import { Level } from '@tiptap/extension-heading';
import ImageResize from 'tiptap-extension-resize-image';
import Table from '@tiptap/extension-table';
import TableCell from '@tiptap/extension-table-cell';
import TableHeader from '@tiptap/extension-table-header';
import TableRow from '@tiptap/extension-table-row';

interface ButtonProps {
  content: string;
}

interface StyledEditorProps {
  buttons?: React.ComponentType<ButtonProps>;
  initialContent?: string;
  onUpdate?: (content: string) => void;
  uploadImage?: (file: File) => Promise<string>;
  toolbar?: React.ReactNode;
  onChange?: (value: string) => void;
  overlay?: React.ReactNode;
  toolbarButtons?: React.ReactNode;
}

export const StyledEditor = ({
  buttons: Buttons,
  initialContent = '',
  uploadImage,
  onUpdate,
  onChange,
  toolbar = null,
  overlay = null,
  toolbarButtons = null,
}: StyledEditorProps) => {
  const [fontSize, setFontSize] = useState<string>('p');
  const [content, setContent] = useState('');

  const editor = useEditor({
    extensions: [
      StarterKit,
      Underline,
      TextStyle,
      FontFamily.configure({
        types: ['textStyle'],
      }),
      TextAlign.configure({
        types: ['heading', 'paragraph'],
      }),
      Image.configure({
        HTMLAttributes: {
          class: 'editor-image',
        },
      }),
      ImageResize,
      Table.configure({
        resizable: true,
        HTMLAttributes: {
          class: 'my-custom-class',
        },
      }),
      TableRow,
      TableCell,
      TableHeader,
    ],
    content: initialContent,
    onUpdate({ editor }) {
      const newContent = editor.getHTML();
      setContent(newContent);
      onUpdate?.(newContent);
      onChange?.(newContent);
    },
  });

  const editorContainerRef = useRef<HTMLDivElement>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    editor?.commands.setContent(initialContent);
    onUpdate?.(initialContent);
    onChange?.(initialContent);
  }, [initialContent]);

  useEffect(() => {
    const box = document.getElementById('editorBox');

    const handleClick = (event: MouseEvent) => {
      if (editorContainerRef.current && event.target instanceof Node) {
        if (editorContainerRef.current.contains(event.target)) {
          if (!(event.target as Element).closest('.ProseMirror')) {
            editor?.commands.focus();
          }
        }
      }
    };

    box?.addEventListener('click', handleClick);

    return () => {
      box?.removeEventListener('click', handleClick);
    };
  }, [editor]);

  useEffect(() => {
    if (editor) {
      editor.on('transaction', () => {
        if (!editor.isActive('heading')) {
          setFontSize('p');
        } else if (editor.isActive('heading', { level: 1 })) {
          setFontSize('1');
        } else if (editor.isActive('heading', { level: 2 })) {
          setFontSize('2');
        }
      });
    }
  }, [editor]);

  const handleImageUpload = async (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const file = event.target.files?.[0];
    if (file && uploadImage) {
      try {
        const imageUrl = await uploadImage(file);
        editor?.chain().focus().setImage({ src: imageUrl }).run();
      } catch (error) {
        console.error('Image upload failed:', error);
        // Handle error (e.g., show a notification to the user)
      }
    }
  };

  if (!editor) {
    return null;
  }

  return (
    <Flex h="100%" direction="column" w="100%">
      <style>{`  .ProseMirror:focus { outline: none !important; } 
      .tiptap {
  :first-child {
    margin-top: 0;
  }

  /* Table-specific styling */
  table {
    border-collapse: collapse;
    margin: 0;
    overflow: hidden;
    table-layout: fixed;
    width: 100%;

    td,
    th {
      border: 1px solid var(--gray-3);
      box-sizing: border-box;
      min-width: 1em;
      padding: 6px 8px;
      position: relative;
      vertical-align: top;

      > * {
        margin-bottom: 0;
      }
    }

    th {
      background-color: var(--gray-1);
      font-weight: bold;
      text-align: left;
    }

    .selectedCell:after {
      background: var(--gray-2);
      content: "";
      left: 0; right: 0; top: 0; bottom: 0;
      pointer-events: none;
      position: absolute;
      z-index: 2;
    }

    .column-resize-handle {
      background-color: var(--purple);
      bottom: -2px;
      pointer-events: none;
      position: absolute;
      right: -2px;
      top: 0;
      width: 4px;
    }
  }

  .tableWrapper {
    margin: 1.5rem 0;
    overflow-x: auto;
  }

  &.resize-cursor {
    cursor: ew-resize;
    cursor: col-resize;
  }
}
      
      `}</style>

      <Flex
        w="100%"
        direction="column"
        bg="white"
        ref={editorContainerRef}
        sx={{
          border: '1px solid #e0e0e0',
          borderRadius: '8px',
          overflow: 'hidden',
          flex: 1,
        }}
      >
        {toolbar}

        <Flex
          align="center"
          sx={{
            borderBottom: '1px solid #e0e0e0',
            padding: '8px',
            backgroundColor: '#f5f5f5',
            position: 'sticky',
            top: 0,
            zIndex: 10,
          }}
        >
          <ScrollArea scrollbarSize={2}>
            <Flex>
              <ActionIcon
                onClick={() => editor.chain().focus().undo().run()}
                disabled={!editor.can().undo()}
                mr={4}
              >
                <IconArrowBackUp size={18} />
              </ActionIcon>
              <ActionIcon
                onClick={() => editor.chain().focus().redo().run()}
                disabled={!editor.can().redo()}
                mr={8}
              >
                <IconArrowForwardUp size={18} />
              </ActionIcon>

              {toolbarButtons}

              <Select
                withinPortal
                data={[
                  { value: 'sans-serif', label: 'Sans Serif' },
                  { value: 'serif', label: 'Serif' },
                  { value: 'monospace', label: 'Monospace' },
                ]}
                defaultValue="sans-serif"
                sx={{ width: '120px' }}
                size="xs"
                mr={8}
                onChange={(value) =>
                  editor
                    .chain()
                    .focus()
                    .setMark('textStyle', { fontFamily: value })
                    .run()
                }
              />

              <Select
                withinPortal
                data={[
                  { value: 'p', label: 'Normal' },
                  { value: '2', label: 'Subtitle' },
                  { value: '1', label: 'Title' },
                ]}
                value={fontSize}
                w="100px"
                size="xs"
                mr={8}
                onChange={(value) => {
                  if (!value) {
                    return;
                  }
                  setFontSize(value);
                  if (value === 'p') {
                    editor.chain().focus().setParagraph().run();
                  } else {
                    editor
                      .chain()
                      .focus()
                      .setHeading({ level: parseInt(value) as Level })
                      .run();
                  }
                }}
              />

              <ActionIcon
                onClick={() => editor.chain().focus().toggleBold().run()}
                color={editor.isActive('bold') ? 'blue' : 'gray'}
              >
                <IconBold size={18} />
              </ActionIcon>
              <ActionIcon
                onClick={() => editor.chain().focus().toggleItalic().run()}
                color={editor.isActive('italic') ? 'blue' : 'gray'}
              >
                <IconItalic size={18} />
              </ActionIcon>
              <ActionIcon
                onClick={() => editor.chain().focus().toggleUnderline().run()}
                color={editor.isActive('underline') ? 'blue' : 'gray'}
              >
                <IconUnderline size={18} />
              </ActionIcon>

              <ActionIcon
                onClick={() =>
                  editor.chain().focus().setTextAlign('left').run()
                }
                color={editor.isActive({ textAlign: 'left' }) ? 'blue' : 'gray'}
              >
                <IconAlignLeft size={18} />
              </ActionIcon>
              <ActionIcon
                onClick={() =>
                  editor.chain().focus().setTextAlign('center').run()
                }
                color={
                  editor.isActive({ textAlign: 'center' }) ? 'blue' : 'gray'
                }
              >
                <IconAlignCenter size={18} />
              </ActionIcon>
              <ActionIcon
                onClick={() =>
                  editor.chain().focus().setTextAlign('right').run()
                }
                color={
                  editor.isActive({ textAlign: 'right' }) ? 'blue' : 'gray'
                }
              >
                <IconAlignRight size={18} />
              </ActionIcon>

              <ActionIcon
                onClick={() =>
                  editor
                    .chain()
                    .focus()
                    .insertTable({ rows: 3, cols: 3, withHeaderRow: true })
                    .run()
                }
              >
                <IconTable size={18} />
              </ActionIcon>
              <ActionIcon
                onClick={() => editor.chain().focus().toggleBulletList().run()}
                color={editor.isActive('bulletList') ? 'blue' : 'gray'}
              >
                <IconList size={18} />
              </ActionIcon>
              <ActionIcon
                onClick={() => editor.chain().focus().toggleOrderedList().run()}
                color={editor.isActive('orderedList') ? 'blue' : 'gray'}
              >
                <IconListNumbers size={18} />
              </ActionIcon>

              {uploadImage && (
                <ActionIcon onClick={() => fileInputRef.current?.click()}>
                  <IconPhoto size={18} />
                </ActionIcon>
              )}
              <input
                type="file"
                ref={fileInputRef}
                style={{ display: 'none' }}
                onChange={handleImageUpload}
                accept="image/*"
              />
            </Flex>
          </ScrollArea>
        </Flex>
        <Box
          id="editorBox"
          sx={{
            padding: '14px 16px',
            flex: 1,
            cursor: 'text',
            overflowY: 'auto',
            '& .editor-image': {
              maxWidth: '100%',
              height: 'auto',
            },
          }}
        >
          <EditorContent editor={editor} data-testid="editor" />
        </Box>

        {overlay}
      </Flex>

      {Buttons && <Buttons content={content} />}
    </Flex>
  );
};
