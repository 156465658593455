import { MantineProvider, Flex, Container, AppShell } from '@mantine/core';
import { Sidebar } from './Sidebar/Sidebar';
import { Header } from './Header/Header';
import { Notifications } from '@mantine/notifications';
import { ModalsProvider } from '@mantine/modals';
import { ProfileGuard } from './Profile/ProfileGuard';
import { CallInterface } from './CallInterface/CallInterface';

interface LayoutProps {
  noMenu?: boolean;
  noScrolling?: boolean;
  children: React.ReactNode;
  noPadding?: boolean;
  noHeader?: boolean;
  hideProfileGuard?: boolean;
}

export function Layout({
  children,
  noScrolling = false,
  noMenu = false,
  noPadding = false,
  hideProfileGuard = false,
  noHeader = false,
}: LayoutProps) {
  return (
    <AppShell
      padding={noPadding ? 0 : 'xs'}
      navbar={!noMenu ? <Sidebar /> : undefined}
      styles={(theme) => ({
        main: {
          backgroundColor:
            theme.colorScheme === 'dark'
              ? theme.colors.dark[8]
              : theme.colors.gray[0],
        },
      })}
      header={noHeader ? undefined : <Header />}
      layout="alt"
    >
      <MantineProvider
        withCSSVariables
        theme={{
          primaryShade: 5,
          fontFamily: "Plus Jakarta Text, 'Fira Sans', sans-serif",
          headings: {
            fontFamily: "Plus Jakarta Display, 'Fira Sans', sans-serif",
          },
          colors: {
            brand: [
              '#e3fffa',
              '#39897A',
              '#2B8675',
              '#1D8471',
              '#0F846E',
              '#00856C',
              '#0C6C5A',
              '#13584B',
              '#184940',
              '#1A3E37',
            ],
          },
          primaryColor: 'brand',
          globalStyles: (theme) => ({
            body: {
              color: '#36403E', // Set your global text color here
            },
          }),
        }}
        withGlobalStyles
        withNormalizeCSS
      >
        <ModalsProvider>
          <Notifications position="top-right" />
          <Container
            fluid
            p={0}
            style={{
              display: 'flex',
              flexDirection: 'column',
              height: noPadding ? '100%' : noScrolling ? '97%' : undefined,
              width: noScrolling ? '100vw' : undefined,
              // background: '#F2F2F2',
              paddingTop: 0,
            }}
          >
            {!hideProfileGuard && <ProfileGuard />}

            <CallInterface />

            <Flex style={{ flex: 1 }} justify={{ sm: 'space-between' }}>
              {children}
            </Flex>
          </Container>
        </ModalsProvider>
      </MantineProvider>
    </AppShell>
  );
}
