import { useDocumentTitle } from '@mantine/hooks';
import { createFileRoute } from '@tanstack/react-router';
import { PhoneSettings } from 'fe/feature/organisation-settings';
import { useOrganisationStore } from 'fe/queries';

export const Route = createFileRoute('/settings/_layout/phone')({
  component: RouteComponent
});

function RouteComponent() {
  useDocumentTitle('Phone - Settings - Hey Rafi');
  const { organisation } = useOrganisationStore();
  if (!organisation) return null;
  return <PhoneSettings organization_id={organisation.id} />;
}
