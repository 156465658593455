import { useDisclosure } from '@mantine/hooks';
import { Box, Button, Modal, Textarea, ThemeIcon } from '@mantine/core';
import { modals } from '@mantine/modals';
import { useCreateNote } from 'fe/queries';
import { isNotEmpty, useForm } from '@mantine/form';
import { IconPlus } from '@tabler/icons-react';

interface ForwardConversationButtonProps {
  leadId: number;
}

export function CreateNoteButton({ leadId }: ForwardConversationButtonProps) {
  const [opened, { open, close: closeModal }] = useDisclosure(false);
  const { mutateAsync: createNote, isPending } = useCreateNote();
  const form = useForm({
    initialValues: {
      content: '',
    },
    validate: {
      content: isNotEmpty(),
    },
  });
  const onSubmit = form.onSubmit(async (values) => {
    await createNote({
      content: values.content,
      lead_id: leadId,
    });
    closeModal();
    modals.closeAll();
  });

  return (
    <Box>
      <Modal opened={opened} onClose={closeModal} title="Create note">
        <form onSubmit={onSubmit}>
          <Textarea
            autosize
            minRows={6}
            maxRows={10}
            label="Content of the new note"
            placeholder="Property is..."
            data-autofocus
            {...form.getInputProps('content')}
          />
          <Button type="submit" loading={isPending} fullWidth mt="md">
            Create
          </Button>
        </form>
      </Modal>

      <Button
        fullWidth
        color="dark"
        variant="subtle"
        size="xs"
        onClick={open}
        leftIcon={
          <ThemeIcon size="xs" variant="subtle">
            <IconPlus />
          </ThemeIcon>
        }
      >
        Note
      </Button>
    </Box>
  );
}
