import { Flex, Text, ThemeIcon } from '@mantine/core';
import React from 'react';
import classes from './PropertyInfoItem.module.scss';

interface PropertyItemProps {
  icon?: React.ReactNode;
  label?: string;
  value: string | number;
  color: string;
  textColor?: string;
  justify?: string;
}

export function PropertyItem({
  icon,
  label,
  value,
  color,
  justify = 'space-between',
  textColor = 'grey',
}: PropertyItemProps) {
  return (
    <Flex
      w="100%"
      p="xs"
      bg="#F1F3F5"
      className={classes.propertyItem}
      justify={justify}
      align="center"
      gap="xs"
    >
      {label ? (
        <Flex gap="xs" align="center">
          <ThemeIcon variant="light" size="sm" color={color}>
            {icon}
          </ThemeIcon>
          <Text fw={500} size="sm" c={textColor}>
            {label}
          </Text>
        </Flex>
      ) : (
        <ThemeIcon variant="light" size="sm" color={color}>
          {icon}
        </ThemeIcon>
      )}

      <Text fz="sm" fw={500}>
        {value}
      </Text>
    </Flex>
  );
}
