import { useEffect, useState } from 'react';
import {
  getCityDropdownData,
  getCountyDropdownData,
  getStateDropdownData,
  getZipDropdownData,
} from './useLocationSelector.utils';
import { useAvailableListingsByLocations, useLocations } from 'fe/queries';
import { Products } from 'shared/db';

interface useLocationSelectorProps {
  value: string[];
  onChange: (zipCodes: number[]) => void;
  organisationId: number;
  fetchCampaignLocations?: boolean;
  states: string[];
  type: Products;
}

export function useLocationSelector({
  value = [],
  onChange,
  organisationId,
  states,
  fetchCampaignLocations = false,
  type,
}: useLocationSelectorProps) {
  const { data, isPending: isLoading } = useLocations(
    organisationId,
    states,
    fetchCampaignLocations,
  );

  const [selectedZipCodes, setSelectedZipCodes] = useState<number[]>([]);
  const [groupBy, setGroupBy] = useState('county');

  useEffect(() => {
    setSelectedZipCodes(value.map(Number));
  }, [value]);

  const cityZipcodes: Record<string, number[]> = {};
  const allUniqueCities: Record<string, string[]> = {};
  const countyZipCodes: Record<string, number[]> = {};
  const stateZipCodes: Record<string, number[]> = {};

  data?.forEach((location) => {
    if (!cityZipcodes[location.city]) {
      cityZipcodes[location.city] = [location.zip];
    } else {
      cityZipcodes[location.city].push(location.zip);
    }

    if (!allUniqueCities[location.county_name]) {
      allUniqueCities[location.county_name] = [location.city];
    } else if (!allUniqueCities[location.county_name].includes(location.city)) {
      allUniqueCities[location.county_name].push(location.city);
    }

    if (!countyZipCodes[location.county_name]) {
      countyZipCodes[location.county_name] = [location.zip];
    } else {
      countyZipCodes[location.county_name].push(location.zip);
    }

    if (!stateZipCodes[location.state_name]) {
      stateZipCodes[location.state_name] = [location.zip];
    } else {
      stateZipCodes[location.state_name].push(location.zip);
    }
  });

  const { data: numListings, isLoading: isLoadingAmount } =
    fetchCampaignLocations
      ? useAvailableListingsByLocations({
          zip_codes: value.map(Number),
          is_fsbo: type === Products.FSBO,
        })
      : { data: 0, isLoading: false };

  const onChangeCallback = (value: [string]) => {
    if (groupBy === 'zip') {
      setSelectedZipCodes(value.map(Number));
      onChange(value.map(Number));
    } else if (groupBy === 'city') {
      const zipCodes = value.map((city) => cityZipcodes[city]).flat();
      setSelectedZipCodes(zipCodes);
      onChange(zipCodes);
    } else if (groupBy === 'state') {
      const zipCodes = value.map((city) => stateZipCodes[city]).flat();
      setSelectedZipCodes(zipCodes);
      onChange(zipCodes);
    } else {
      const zipCodes = value.map((county) => countyZipCodes[county]).flat();
      setSelectedZipCodes(zipCodes);
      onChange(zipCodes);
    }
  };

  if (groupBy === 'city') {
    const { dropdownData, dropdownValue } = getCityDropdownData({
      cityZipcodes,
      selectedZipCodes,
    });
    return {
      isLoading,
      groupBy,
      setGroupBy,
      dropdownOnChange: onChangeCallback,
      dropdownData,
      dropdownValue,
      numListings,
      isLoadingAmount,
    };
  }

  if (groupBy === 'county') {
    const { dropdownData, dropdownValue } = getCountyDropdownData({
      countyZipCodes,
      selectedZipCodes,
    });
    return {
      isLoading,
      groupBy,
      setGroupBy,
      dropdownOnChange: onChangeCallback,
      dropdownData,
      dropdownValue,
      numListings,
      isLoadingAmount,
    };
  }

  if (groupBy === 'state') {
    const { dropdownData, dropdownValue } = getStateDropdownData({
      stateZipCodes,
      selectedZipCodes,
    });
    return {
      isLoading,
      groupBy,
      setGroupBy,
      dropdownOnChange: onChangeCallback,
      dropdownData,
      dropdownValue,
      numListings,
      isLoadingAmount,
    };
  }

  const { dropdownData, dropdownValue } = getZipDropdownData({
    locations: data || [],
    selectedZipCodes,
  });

  return {
    isLoading,
    groupBy,
    setGroupBy,
    dropdownOnChange: onChangeCallback,
    dropdownData,
    dropdownValue,
    numListings,
    isLoadingAmount,
  };
}
