import {
  LocationsForm,
  OrganisationBasicForm
} from 'fe/feature/organisation-settings';
import { PageLoader } from '../../components/PageLoader';
import { useIsAdmin, useOrganisationStore } from 'fe/queries';
import { Divider } from '@mantine/core';
import { useDocumentTitle } from '@mantine/hooks';
import { Typography } from 'fe/ui/shared';
import { createFileRoute } from '@tanstack/react-router';

export const Route = createFileRoute('/settings/_layout/')({
  component: SettingsPage
});

export function SettingsPage() {
  const { data: isAdmin } = useIsAdmin();
  const { organisation, isLoading } = useOrganisationStore();
  useDocumentTitle(`Settings - Hey Rafi`);
  if (isLoading || !organisation) return <PageLoader />;

  return (
    <>
      <OrganisationBasicForm organisation={organisation} />

      {isAdmin && (
        <>
          <Divider my="xl" />
          <Typography variant="label">Locations</Typography>
          <LocationsForm organisation={organisation} />
        </>
      )}
    </>
  );
}
