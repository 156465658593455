import { useState, useEffect } from 'react';
import { ActionIcon, Box, Divider, Flex } from '@mantine/core';
import {
  FeContract,
  useDeleteOrganisationContract,
  useIsAdmin,
  useOrganisationContracts,
  useOrganisationStore,
} from 'fe/queries';
import { IconPlus } from '@tabler/icons-react';
import { ContractForm } from './ContractForm';
import { modals } from '@mantine/modals';
import { ContractCard } from './ContractCard';
import { ContractFields } from './ContractFields';

interface ContractManagementProps {
  title?: string;
  onChange?: (value: number | null) => void;
  isLoadingChange?: boolean;
  selectedValue?: number | null;
  hideFields?: boolean;
}

export function ContractManagement({
  title = 'Contracts',
  onChange,
  isLoadingChange,
  selectedValue,
  hideFields = false,
}: ContractManagementProps) {
  const { organisation } = useOrganisationStore();
  if (!organisation) return null;

  const [internalValue, setInternalValue] = useState<number | null>(
    selectedValue || null,
  );

  useEffect(() => {
    setInternalValue(selectedValue || null);
  }, [selectedValue]);

  const { data: isAdmin } = useIsAdmin();
  const { data: contracts } = useOrganisationContracts(organisation.id);
  const { mutateAsync: deleteContract, isPending: isDeleting } =
    useDeleteOrganisationContract();

  const open = (contract?: FeContract) => {
    modals.open({
      title: contract ? 'Update contract' : 'Create contract',
      children: (
        <ContractForm onSuccess={() => modals.closeAll()} contract={contract} />
      ),
    });
  };

  const handleContractClick = (contractId: number) => {
    if (onChange) {
      const newValue = internalValue === contractId ? null : contractId;
      setInternalValue(newValue);
      onChange(newValue);
    }
  };

  return (
    <Flex display="flex" direction="column" h="100%" py="md" pr="xs">
      <Box px="xl" maw={800} w="100%">
        {isAdmin && (
          <ActionIcon variant="filled" color="brand" onClick={() => open()}>
            <IconPlus size="1.125rem" />
          </ActionIcon>
        )}

        <Flex mt="xl" direction="column" gap="md" h="100%" w="100%">
          {contracts?.map((contract) => (
            <ContractCard
              key={contract.id}
              contract={contract}
              onEdit={open}
              onDelete={() => deleteContract({ contract_id: contract.id })}
              isDeleting={isDeleting}
              isSelected={internalValue === contract.id}
              onChange={() => handleContractClick(contract.id)}
              isLoadingChange={isLoadingChange}
            />
          ))}
        </Flex>

        {isAdmin && !hideFields && (
          <Box h={800} px="xl" mt="xl">
            <Divider my="xl" />

            <ContractFields />
          </Box>
        )}
      </Box>
    </Flex>
  );
}
