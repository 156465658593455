import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import {
  AvailableLocation,
  Campaign,
  fetchAddressByZipcode,
  fetchAvailableLocations,
  fetchPropertyAddress,
  Property,
} from 'shared/db';
import { supabase } from '../utils';
import { trpc } from '../setup';

const locationKeys = {
  all: ['locations'] as const,
  organisation: (organisationId: number) => [
    ...locationKeys.all,
    organisationId,
  ],
  organisationState: (organisationId: number, state: string) => [
    ...locationKeys.organisation(organisationId),
    state,
  ],
  campaignLocations: (campaignId: number) => ['campaign_locations', campaignId],
  availableOrgLocations: (organisationId: number) => [
    'available_organisation_locations',
    organisationId,
  ],
};

interface OrganisationLocation {
  organisation_id: number;
  zip_code: number;
}

export const useLocation = (property?: Property) =>
  useQuery({
    queryKey: ['location', property?.zip_code],
    queryFn: async () =>
      property ? await fetchPropertyAddress(supabase, property) : '',
  });

export const useAddress = (zipCode?: number) =>
  useQuery({
    queryKey: ['location', zipCode],
    queryFn: async () =>
      zipCode ? await fetchAddressByZipcode(supabase, zipCode) : null,
  });

export const useLocations = (
  organisationId: number,
  states: string[],
  fetchCampaignLocations = false,
) => {
  if (fetchCampaignLocations) {
    return useQuery({
      queryKey: locationKeys.availableOrgLocations(organisationId),
      queryFn: () => fetchAvailableCampaignLocations(organisationId),
    });
  }

  return useStatesZipCodes({
    organization_id: organisationId,
    states,
  });
};

export const useStatesZipCodes =
  trpc.organization.getAvailableZipCodes.useQuery;

export const useAvailableLocations = (
  organisationId: number,
  state: string,
  fetchCampaignLocations = false,
) =>
  useQuery({
    queryKey: !fetchCampaignLocations
      ? ['locations', organisationId, state]
      : ['available_organisation_locations', organisationId, state],
    queryFn: () =>
      !fetchCampaignLocations
        ? fetchAvailableLocations(supabase, organisationId, state)
        : fetchAvailableCampaignLocations(organisationId),
  });

export async function fetchAvailableCampaignLocations(
  organisationId: number,
): Promise<AvailableLocation[]> {
  if (!organisationId) {
    return [];
  }
  const { data, error } = await supabase
    .from('available_locations')
    .select('*')
    .eq('id', organisationId);
  if (error) {
    throw new Error("Couldn't fetch locations");
  }
  return data;
}

export async function updateOrganisationLocation(
  organisationId: number,
  locations: number[],
  currentLocations: number[],
): Promise<OrganisationLocation[]> {
  const toDelete = currentLocations.filter(
    (location) => !locations.includes(location),
  );
  if (toDelete && toDelete.length > 0) {
    await supabase
      .from('organisation_locations')
      .delete()
      .in('zip_code', toDelete);
  }

  if (locations.length === 0) {
    return [];
  }

  const mappedLocations = locations.map((location) => ({
    organisation_id: organisationId,
    zip_code: location,
  }));

  const { data, error } = await supabase
    .from('organisation_locations')
    .upsert(mappedLocations)
    .select();

  if (error) {
    throw new Error("Couldn't update locations");
  }

  return data;
}

export const useUpdateOrganisationLocations = () => {
  const utils = trpc.useUtils();

  return trpc.organization.updateSelectedZipCodes.useMutation({
    onSuccess: (data, params) => {
      utils.organization.getSelectedZipCodes.setData(
        { organization_id: params.organization_id },
        data,
      );
    },
  });
};

export async function fetchOrganisationLocations(
  organisationId: number,
): Promise<OrganisationLocation[]> {
  const { data, error } = await supabase
    .from('organisation_locations')
    .select('*')
    .eq('organisation_id', organisationId);

  if (error) {
    throw new Error("Couldn't fetch locations");
  }

  return data;
}

export const useOrganisationLocations =
  trpc.organization.getSelectedZipCodes.useQuery;

//  Campaigns

export async function updateCampaignLocation(
  campaign: Campaign,
  locations: number[],
) {
  await supabase
    .from('campaign_locations')
    .delete()
    .eq('campaign_id', campaign.id);

  if (locations.length === 0) {
    return { campaign, data: [] };
  }

  const mappedLocations = locations.map((location) => ({
    campaign_id: campaign.id,
    zip_code: location,
  }));

  const { data, error } = await supabase
    .from('campaign_locations')
    .upsert(mappedLocations)
    .select();

  if (error) {
    throw new Error("Couldn't update campaign locations");
  }

  return { campaign, data };
}

export const useUpdateCampaignLocations = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({
      campaign,
      locations,
    }: {
      campaign: Campaign;
      locations: number[];
    }) => updateCampaignLocation(campaign, locations),
    onSuccess: ({ campaign, data }) => {
      queryClient.setQueriesData<OrganisationLocation[]>(
        { queryKey: locationKeys.campaignLocations(campaign.id) },
        data,
      );
      queryClient.setQueriesData<Campaign[]>(
        { queryKey: ['campaigns', campaign.organisation_id, campaign.type] },
        (previous) =>
          previous?.map((prevCampaign) =>
            prevCampaign.id === campaign.id
              ? {
                  ...prevCampaign,
                  usa_zip_codes: data.map(
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    (item) => ({ zip: item.zip_code }) as any,
                  ),
                }
              : prevCampaign,
          ) || [],
      );
    },
  });
};
