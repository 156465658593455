import { Container, Flex, Title, Progress, Image } from '@mantine/core';
import {
  useAnalyzeComparables,
  useAnalyzePropertyInfo,
  useAnalyzeCondition,
  useOrganisationStore,
  useAnalyzeContact
} from 'fe/queries';
import { SearchIllustration, Typography } from 'fe/ui/shared';
import { useState } from 'react';
import { Property, PropertyStatus } from 'shared/db';
import Autocomplete from 'react-google-autocomplete';
import classes from './SearchPage.module.scss';
import { useNavigate } from '@tanstack/react-router';
import './SearchPage.module.scss';
import { useCountUp } from 'use-count-up';
import { notifications } from '@mantine/notifications';
import { LogoLoader } from './LogoLoader';

export function SearchPage() {
  const navigate = useNavigate();
  const { organisation } = useOrganisationStore();
  const organizationId = organisation?.id || 0;
  const { mutateAsync: analyzePropertyInfo } =
    useAnalyzePropertyInfo(organizationId);
  const { mutateAsync: analyzeComparables } =
    useAnalyzeComparables(organizationId);
  const { mutateAsync: analyzeCondition } = useAnalyzeCondition(organizationId);
  const { mutateAsync: analyzeContact } = useAnalyzeContact(organizationId);

  const [isLoading, setIsLoading] = useState(false);

  const { value } = useCountUp({
    isCounting: isLoading,
    end: 100,
    duration: 15
  });

  const startAnalysis = async (address: string) => {
    setIsLoading(true);
    try {
      const propertyResponse = await analyzePropertyInfo(address);
      if (propertyResponse.status === 'error') {
        return notifications.show({
          title: 'Error fetching property info',
          message: propertyResponse.message,
          color: 'red'
        });
      }

      const propertyData: Property = propertyResponse.data.property;
      const promises = [
        analyzeComparables({
          street_address: propertyData.street_address,
          zip_code: propertyData.zip_code
        }),
        analyzeContact({
          street_address: propertyData.street_address,
          zip_code: propertyData.zip_code
        })
      ];
      if (propertyData.status === PropertyStatus.FOR_SALE) {
        promises.push(
          analyzeCondition({
            street_address: propertyData.street_address,
            zip_code: propertyData.zip_code
          })
        );
      }
      await Promise.all(promises);
      navigate({
        to: `/offer/$zip_code/$street_address`,
        params: {
          zip_code: String(propertyData.zip_code),
          street_address: propertyData.street_address
        }
      });
    } catch (error) {
      setIsLoading(false);
      notifications.show({
        title: 'Error fetching property info',
        message: 'Please contact support@heyrafi.com if you see this message.',
        color: 'red'
      });
      return;
    }
  };

  if (isLoading) {
    return (
      <Container h="100%" w={400}>
        <Flex
          direction="column"
          align="center"
          h="100%"
          justify="center"
          gap="xl"
        >
          <LogoLoader />

          <Progress
            value={Number(value)}
            w="100%"
            h={40}
            size="xl"
            striped
            animate
            color="#00D6B9"
          />
        </Flex>
      </Container>
    );
  }
  return (
    <Container h="100%">
      <Flex align="center" h="100%" justify="center" gap="xl">
        <Image src={SearchIllustration} maw={300} />

        <Flex direction="column" gap="xl">
          <Flex direction="column" gap="sm">
            <Title order={1} size={48}>
              Analyze & Send Offer
            </Title>

            <Typography c="dimmed" align="center" size={22}>
              Search for any property you wish to send an offer to
            </Typography>
          </Flex>

          <Autocomplete
            apiKey={'AIzaSyCD93ZyTUHGuOM_7Am8Blq3EMtKxYq2ybM'}
            onPlaceSelected={(place) => startAnalysis(place.formatted_address)}
            className={classes.searchInput}
            options={{
              types: ['address'],
              componentRestrictions: { country: 'us' }
            }}
          />
        </Flex>
      </Flex>
    </Container>
  );
}
