import { Container, Flex } from '@mantine/core';
import { Title, Text, Anchor } from '@mantine/core';

export function PrivacyPolicy() {
  return (
    <Container size="sm" style={{ textAlign: 'center' }}>
      <Flex direction="column" gap="md">
        <Title order={1}>Privacy Policy</Title>

        <Text>
          Hey Rafi is committed to protecting the privacy of its users. This
          Privacy Policy outlines how we collect, use, disclose, and protect the
          information provided by users of our marketing software platform
          ("Platform").
        </Text>

        <Title order={2}>1. Information Collection:</Title>
        <Text>
          We collect information provided by users when they register for an
          account, use the Platform, or communicate with us. This may include
          personal information such as name, email address, company information,
          company domain information, and payment details.
        </Text>

        <Title order={2}>2. Use of Information:</Title>
        <Text>
          We use the information collected to provide and improve our services,
          process transactions, communicate with recipients, and personalize
          their experience on the Platform. We may also use anonymized and
          aggregated data, including marketing strategies and best practices
          derived from user interactions with the Platform, to improve our
          services and share insights with other users.
        </Text>

        <Title order={2}>3. Disclosure of Information:</Title>
        <Text>
          We do not share any of our users' business information with third
          parties without their explicit consent, except where required by law.
          However, we may share anonymized and aggregated data with trusted
          partners or service providers for the purpose of improving our
          services.
        </Text>

        <Title order={2}>4. Protection of Information:</Title>
        <Text>
          We employ industry-standard security measures to protect the
          information provided by our users. However, no method of transmission
          over the internet or electronic storage is 100% secure, and we cannot
          guarantee absolute security of user data.
        </Text>

        <Title order={2}>5. Changes to Privacy Policy:</Title>
        <Text>
          We reserve the right to update or modify this Privacy Policy at any
          time. Users will be notified of any changes via email or through the
          Platform's notification system. By continuing to use the Platform
          after such changes, users agree to the updated Privacy Policy.
        </Text>

        <Title order={2}>6. Contact Us:</Title>
        <Text>
          If you have any questions or concerns about our Privacy Policy or the
          handling of your information, please contact us at
          <Anchor href="mailto:support@heyrafi.com">support@heyrafi.com</Anchor>
        </Text>

        <Text>
          By using our Platform, you acknowledge that you have read, understood,
          and agree to the terms of this Privacy Policy.
        </Text>

        <Text>
          Please ensure to review and customize this privacy policy according to
          your specific requirements and legal advice.
        </Text>
      </Flex>
    </Container>
  );
}
