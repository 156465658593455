import { Button } from '@mantine/core';
import { usePhoneStore, isCallingEnabled } from 'fe/queries';

interface CallButtonProps {
  name: string;
  number: string;
  contactId: number;
}

export function CallButton({ name, number, contactId }: CallButtonProps) {
  const isEnabled = isCallingEnabled();
  const makeCall = usePhoneStore((s) => s.makeCall);
  return (
    <Button
      disabled={!isEnabled}
      onClick={() => makeCall({ name, number, contactId })}
      size="xs"
      compact
    >
      Call
    </Button>
  );
}
