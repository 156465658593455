import {
  Button,
  Flex,
  Image,
  Modal,
  TextInput,
  ThemeIcon,
} from '@mantine/core';
import {
  useAnalyzeInbox,
  useForwardConversation,
  useIsAdmin,
} from 'fe/queries';
import { IconArrowBack, IconSparkles } from '@tabler/icons-react';
import { useDisclosure } from '@mantine/hooks';
import { isNotEmpty, useForm } from '@mantine/form';
import { ZillowLogo } from 'fe/ui/shared';
import { CreateNoteButton } from '../CreateNoteButton';

interface ConversationDetailProps {
  url: string;
  leadId: number;
  toggleReply: () => void;
}

export function ConversationActions({
  url,
  leadId,
  toggleReply,
}: ConversationDetailProps) {
  const [opened, { close: closeModal }] = useDisclosure(false);
  const { data: isAdmin } = useIsAdmin();
  const { mutateAsync: forward, isPending: isForwarding } =
    useForwardConversation();
  const { mutateAsync: analyze, isPending: isAnalyzing } = useAnalyzeInbox();
  const form = useForm({
    initialValues: {
      email: '',
    },
    validate: {
      email: isNotEmpty(),
    },
  });
  const onSubmit = form.onSubmit(async (values) => {
    await forward({
      forward_to_email: values.email,
      lead_id: leadId,
    });
    closeModal();
  });

  return (
    <>
      <Modal opened={opened} onClose={closeModal} title="Forward">
        <form onSubmit={onSubmit}>
          <TextInput
            label="Email to send conversations to"
            placeholder="john@doe.com"
            data-autofocus
            {...form.getInputProps('email')}
          />
          <Button type="submit" loading={isForwarding} fullWidth mt="md">
            Submit
          </Button>
        </form>
      </Modal>
      <Flex gap="xs">
        <Button
          variant="default"
          size="xs"
          leftIcon={
            <ThemeIcon size="xs" variant="empty">
              <IconArrowBack />
            </ThemeIcon>
          }
          onClick={toggleReply}
        >
          Email
        </Button>

        <CreateNoteButton leadId={leadId} />

        <Button
          size="xs"
          variant="subtle"
          c="#0069FF"
          onClick={() => {
            window.open(url, '_blank');
          }}
          leftIcon={<Image src={ZillowLogo} width={18} />}
        >
          Zillow
        </Button>

        {isAdmin && (
          <Button
            onClick={() => analyze({ lead_id: leadId })}
            loading={isAnalyzing}
            size="xs"
            variant="light"
            leftIcon={
              <ThemeIcon variant="empty">
                <IconSparkles />
              </ThemeIcon>
            }
          >
            Analyze
          </Button>
        )}
      </Flex>
    </>
  );
}
