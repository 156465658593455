import { OrganisationBasicForm } from 'fe/feature/organisation-settings';
import { PageLoader } from '../../components/PageLoader';
import { useIsAdmin, useOrganisationStore } from 'fe/queries';
import {
  Flex,
  Button,
  Modal,
  Text,
  ScrollArea,
  Container,
  Divider,
  Title,
  NavLink
} from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import {
  IconMessageCircle,
  IconPaperclip,
  IconTool,
  IconSearch,
  IconCurrencyDollar,
  IconUser,
  IconSettings,
  IconMail,
  IconPhone
} from '@tabler/icons-react';
import classes from './layout.module.scss';
import { modals } from '@mantine/modals';
import {
  createFileRoute,
  Outlet,
  ParseRoute,
  useLocation,
  useNavigate
} from '@tanstack/react-router';
import { routeTree } from '../../routeTree.gen';

export const Route = createFileRoute('/settings/_layout')({
  component: SettingsLayout
});

type ValidRoutes = ParseRoute<typeof routeTree>['fullPath'];

interface RouteConfig {
  title: string;
  description: string;
  icon: React.ReactNode;
}

const routes: Partial<Record<ValidRoutes, RouteConfig>> = {
  '/settings': {
    title: 'General',
    description: 'Basic organization settings',
    icon: <IconSettings size="1rem" stroke={1.5} />
  },
  '/settings/members': {
    title: 'Team members',
    description: 'Manage your team',
    icon: <IconUser size="1rem" stroke={1.5} />
  },
  '/settings/contracts': {
    title: 'Contracts',
    description: 'Manage your contracts',
    icon: <IconPaperclip size="1rem" stroke={1.5} />
  },
  '/settings/workflows': {
    title: 'Emails & Workflows',
    description: 'Choose how you want to communicate with your leads',
    icon: <IconMail size="1rem" stroke={1.5} />
  },
  '/settings/repairs': {
    title: 'Repair costs',
    description: 'Decide how you want to calculate repair costs',
    icon: <IconTool size="1rem" stroke={1.5} />
  },
  '/settings/comps': {
    title: 'Comps search criteria',
    description: 'Set up your comps search criteria',
    icon: <IconSearch size="1rem" stroke={1.5} />
  },
  '/settings/pricing': {
    title: 'Pricing strategy',
    description: 'Set up your pricing strategy',
    icon: <IconCurrencyDollar size="1rem" stroke={1.5} />
  },
  '/settings/crm': {
    title: 'CRM Integration',
    description: 'Set up your CRM integration',
    icon: <IconMessageCircle size="1rem" stroke={1.5} />
  },
  '/settings/phone': {
    title: 'Phone settings',
    description: 'Set up your phone settings',
    icon: <IconPhone size="1rem" stroke={1.5} />
  },
  '/settings/email': {
    title: 'Email settings',
    description: 'Set up email for your organization',
    icon: <IconMessageCircle size="1rem" stroke={1.5} />
  }
};
export function SettingsLayout() {
  const navigate = useNavigate({});
  const pathname = useLocation({
    select: (location) => location.pathname
  });

  const { data: isAdmin } = useIsAdmin();
  const { organisation, isLoading, archive } = useOrganisationStore();
  const [
    openedOrganisation,
    { open: openOrganisation, close: closeOrganisation }
  ] = useDisclosure(false);

  const openArchiveModal = () =>
    modals.openConfirmModal({
      title: 'Archive organization',
      centered: true,
      children: (
        <Text size="sm">
          Are you sure you want to archive the organization {organisation?.name}
          ? You'll need to contact support to restore it.
        </Text>
      ),
      labels: { confirm: 'Archive organization', cancel: 'Cancel' },
      confirmProps: { color: 'red' },
      onConfirm: archive
    });

  if (isLoading || !organisation) return <PageLoader />;

  return (
    <Flex w="100%" gap="md" my="md">
      <Modal
        opened={openedOrganisation}
        onClose={closeOrganisation}
        title="Create organization"
        size="lg"
      >
        <OrganisationBasicForm onSucess={closeOrganisation} />
      </Modal>

      <Flex justify="space-between" direction="column">
        <Flex direction="column">
          {Object.keys(routes).map((routeKey) => {
            const route = routes[routeKey as ValidRoutes];
            if (!route) return null;
            return (
              <NavLink
                key={routeKey}
                classNames={{ root: classes.navLink }}
                variant="light"
                color="dark"
                active={pathname === routeKey}
                label={route.title}
                description={route.description}
                icon={route.icon}
                onClick={() => navigate({ to: routeKey })}
              />
            );
          })}
        </Flex>

        <Flex direction="column" gap="xs" px="xs">
          {isAdmin && (
            <>
              <Button
                w="100%"
                variant="outline"
                color="red"
                onClick={openArchiveModal}
              >
                Archive organization
              </Button>
              <Button w="100%" onClick={openOrganisation} variant="outline">
                Create new organization
              </Button>
            </>
          )}
        </Flex>
      </Flex>

      <Container style={{ flex: 1, height: '90%', position: 'relative' }}>
        <Title order={1}>{routes[pathname as ValidRoutes]?.title}</Title>

        <Divider my="sm" />

        <div className={classes.inboxWrapper}>
          <ScrollArea style={{ height: '100%' }} pr="lg">
            <Outlet />
          </ScrollArea>
        </div>
      </Container>
    </Flex>
  );
}
