import {
  Flex,
  Header as MantineHeader,
  Button,
  Modal,
  Text,
  Avatar,
  Menu,
  ActionIcon,
  Drawer,
  Loader,
  Paper,
} from '@mantine/core';
import { CampaignForm } from '../CampaignForm/CampaignForm';
import { useDisclosure } from '@mantine/hooks';
import {
  isCallingEnabled,
  useLogout,
  useOrganisationStore,
  useUnassignedCalls,
  useUserProfile,
} from 'fe/queries';
import { getInitials } from 'shared/formatter';
import {
  IconBell,
  IconChevronDown,
  IconDoorExit,
  IconInfoCircle,
  IconUser,
} from '@tabler/icons-react';
import { modals } from '@mantine/modals';
import { ProfileForm } from './../Profile/ProfileForm';
import { skipToken } from '@tanstack/react-query';
import { UnassignedCall } from './UnassignedCall';
export function Header() {
  const [opened, { open, close }] = useDisclosure(false);
  const { data: profileData } = useUserProfile();
  const { organisation } = useOrganisationStore();
  const [
    openedNotifications,
    { open: openNotifications, close: closeNotifications },
  ] = useDisclosure(false);
  const callingEnabled = isCallingEnabled();
  const shouldFetch = organisation?.id && openedNotifications && callingEnabled;

  const { data: unassignedCalls, isLoading: isLoadingUnassignedCalls } =
    useUnassignedCalls(
      shouldFetch
        ? {
            organization_id: organisation?.id,
          }
        : skipToken,
    );

  const { mutateAsync: logout } = useLogout();

  const profile = profileData?.profile;

  const openEditProfile = () => {
    modals.open({
      title: 'Edit profile',
      size: 'lg',
      children: <ProfileForm onSuccess={modals.closeAll} />,
    });
  };

  return (
    <MantineHeader height={50}>
      <Drawer
        withinPortal={false}
        position="left"
        opened={openedNotifications}
        onClose={closeNotifications}
        title="Unassigned calls"
      >
        <Flex direction="column" w="100%" align="center" px="md">
          {!isLoadingUnassignedCalls && unassignedCalls?.length === 0 && (
            <Paper p="md" bg="blue.0" w="100%">
              <Flex
                direction="column"
                w="100%"
                h="100%"
                justify="center"
                align="center"
              >
                <IconInfoCircle size="1.125rem" />
                <Text>No unassigned calls</Text>
              </Flex>
            </Paper>
          )}

          {isLoadingUnassignedCalls ? (
            <Loader />
          ) : (
            unassignedCalls?.map((call, index) => (
              <UnassignedCall
                {...call}
                key={call.id}
                hideDivider={index === unassignedCalls.length - 1}
              />
            ))
          )}
        </Flex>
      </Drawer>

      <Modal
        opened={opened}
        onClose={close}
        size="md"
        title="Create new campaign"
        centered
      >
        <CampaignForm smallMode />
      </Modal>

      <Flex
        key={'logo'}
        align="center"
        justify="space-between"
        mb="xs"
        w="100%"
        h="100%"
        pr="xs"
        pl="xs"
      >
        <Button variant="outline" onClick={open}>
          Create campaign
        </Button>

        <Flex align="center" gap="xs">
          {callingEnabled && (
            <ActionIcon onClick={openNotifications}>
              <IconBell size="1.125rem" />
            </ActionIcon>
          )}

          {profile && (
            <Menu shadow="md" width={200} position="bottom-start">
              <Menu.Target>
                <Button
                  variant="subtle"
                  color="gray"
                  style={{ border: 'none' }}
                  rightIcon={<IconChevronDown size={14} />}
                >
                  <Flex gap="xs" align="center">
                    <Avatar
                      src={profile.picture}
                      color="blue"
                      size="sm"
                      radius="xl"
                    >
                      {getInitials(
                        `${profile.first_name} ${profile.last_name}`,
                      )}
                    </Avatar>
                    <Flex direction="column">
                      <Text size="xs">{`${profile.first_name} ${profile.last_name}`}</Text>
                    </Flex>
                  </Flex>
                </Button>
              </Menu.Target>
              <Menu.Dropdown>
                <Menu.Item
                  onClick={openEditProfile}
                  icon={<IconUser size={14} />}
                >
                  Edit profile
                </Menu.Item>
                <Menu.Item
                  c="dimmed"
                  icon={<IconDoorExit size={14} />}
                  onClick={() => logout()}
                >
                  Sign out
                </Menu.Item>
              </Menu.Dropdown>
            </Menu>
          )}
        </Flex>
      </Flex>
    </MantineHeader>
  );
}
