import {
  Container,
  Flex,
  Title,
  Image,
  Anchor,
  PinInput,
  Loader,
} from '@mantine/core';
import { useVerifyOtp } from 'fe/queries';
import { Gmail, Outlook, Typography } from 'fe/ui/shared';
import { useState } from 'react';

interface CheckYourEmailProps {
  email: string;
}

export function CheckYourEmail({ email }: CheckYourEmailProps) {
  const { mutateAsync: verify, isPending } = useVerifyOtp();
  const [error, setError] = useState<string | null>(null);

  const onComplete = async (value: string) => {
    const result = await verify({
      email,
      otp: value,
    });
    if (!result) {
      setError('Invalid code');
    } else {
      window.location.reload();
    }
  };
  return (
    <Container size={600}>
      <Flex mb={40} direction="column">
        <Typography align="center" c="dimmed" variant="label" fz="xl">
          SIGN IN
        </Typography>
        <Title align="center" order={1}>
          Check your email for a code
        </Title>
        <Typography c="dimmed" fz="xl" mt="xs" align="center" w="100%">
          We've sent an email to {email} with a 6-digits code to log in. Please
          enter the code below.
        </Typography>

        <Flex
          justify="center"
          mt="xl"
          direction="column"
          align="center"
          gap="xs"
        >
          {isPending && <Loader size="xs" />}

          <PinInput
            oneTimeCode
            size="xl"
            inputMode="numeric"
            length={6}
            error={Boolean(error)}
            onComplete={onComplete}
          />
          {error && (
            <Typography c="red" align="center">
              {error}
            </Typography>
          )}
        </Flex>
      </Flex>

      <Flex mt={100} justify="center" gap="xl">
        <Anchor href="https://mail.google.com/mail/u/0/" target="__blank">
          <Flex align="center" gap="xs">
            <Image src={Gmail} width={24} />
            Open Gmail
          </Flex>
        </Anchor>

        <Anchor href="https://outlook.live.com/mail/0/inbox" target="__blank">
          <Flex align="center" gap="xs">
            <Image src={Outlook} width={24} />
            Open Outlook
          </Flex>
        </Anchor>
      </Flex>
    </Container>
  );
}
