import { useMutation } from '@tanstack/react-query';
import { uploadOrganisationContract } from 'shared/db';
import { supabase } from '../utils';
import { RouterOutput, trpc } from '../setup';
import { StandardFonts } from 'pdf-lib';

export type FeContract =
  RouterOutput['organization']['contracts']['getContracts'][0];

export const useOrganisationContracts = (organisationId: number) =>
  trpc.organization.contracts.getContracts.useQuery({
    organization_id: organisationId,
  });

interface CreateContractParams {
  file: File;
  label: string;
  buyer_name: string | null;
  font: StandardFonts;
}

export const useCreateOrganisationContract = (organisationId: number) => {
  const utils = trpc.useUtils();
  const { mutateAsync: createContract } =
    trpc.organization.contracts.createContract.useMutation();
  return useMutation({
    mutationFn: async ({
      file,
      label,
      buyer_name,
      font,
    }: CreateContractParams) => {
      const filename = await uploadOrganisationContract(
        supabase,
        organisationId,
        file,
      );
      return createContract({
        organization_id: organisationId,
        filename,
        label,
        buyer_name,
        font,
      });
    },
    onSuccess: (data) => {
      utils.organization.contracts.getContracts.setData(
        { organization_id: organisationId },
        (old_data) => [...(old_data || []), data],
      );
    },
  });
};

export const useUpdateOrganisationContract = () => {
  const utils = trpc.useUtils();
  return trpc.organization.contracts.updateContract.useMutation({
    onSuccess: (data) => {
      utils.organization.contracts.getContracts.setData(
        { organization_id: data.organisation_id },
        (old_data) =>
          (old_data || []).map((c) => (c.id === data.id ? data : c)),
      );
      utils.organization.contracts.getExampleContract.invalidate({
        contract_id: data.id,
      });
    },
  });
};

export const useDeleteOrganisationContract = () => {
  const utils = trpc.useUtils();
  return trpc.organization.contracts.deleteContract.useMutation({
    onSuccess: (data, params) => {
      utils.organization.contracts.getContracts.setData(
        { organization_id: data.organisation_id },
        (old_data) => (old_data || []).filter((c) => c.id !== data.id),
      );
      params;
    },
  });
};

export const useOrganisationContractPDF =
  trpc.organization.contracts.getExampleContract.useQuery;
