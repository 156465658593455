import { InboxActivity } from 'fe/queries';
import {
  Badge,
  Box,
  Divider,
  Flex,
  Grid,
  Paper,
  Spoiler,
  Text,
  ThemeIcon,
} from '@mantine/core';
import classes from './ConversationMessage.module.scss';
import {
  formatActivityDate,
  formatDurationInterval,
  formatStatusToLabel,
} from 'shared/formatter';
import { AttachmentCard } from './../AttachmentCard';
import { IconSparkles } from '@tabler/icons-react';

interface ConversationMessageProps {
  item: InboxActivity;
}

export function ConvoItem({ item }: ConversationMessageProps) {
  let color = '';
  let beforeName = '';
  const isEmail = item.type === 'message' && item.recipients;
  const isSMS = !isEmail;
  switch (item.type) {
    case 'message':
      color = 'white';
      if (isSMS) {
        beforeName = 'SMS';
      } else {
        beforeName = 'Email';
      }
      break;
    case 'note':
      color = 'yellow.0';
      beforeName = 'Note';
      break;
    case 'call':
      color = 'white';
      if (item.outbound) {
        beforeName = 'Outbound call';
      } else {
        beforeName = 'Inbound call';
      }
      break;
    default:
      color = 'gray.1';
      beforeName = 'Lead status updated';
  }

  let userName = null;
  let userNameLabel = 'from';
  if (isEmail) {
    userName = item.user_name?.trim() || null;
  } else {
    userNameLabel = 'by';
    userName = item.user_name?.trim() || 'Unknown';
  }

  return (
    <Paper radius="sm" p="md" withBorder bg={color}>
      <Flex align="center" justify="space-between" w="100%">
        <Box>
          <Flex gap={4} align="center" wrap="wrap">
            <Text fw="bold">{beforeName}</Text>
            {userName && (
              <Text>
                {userNameLabel} {userName}
              </Text>
            )}
            {isEmail && <Text c="dimmed">{`<${item.from_email}>`}</Text>}
          </Flex>

          {isEmail && item.recipients && item.recipients.length > 0 && (
            <Text fz="sm" c="dimmed">
              to {item.recipients.join(', ')}
            </Text>
          )}
        </Box>

        <Flex direction="column" gap="xs" align="flex-end">
          <Text fz="xs" c="dimmed">
            {formatActivityDate(item.created_at)}
          </Text>
          {item.type === 'status_change' ||
          (item.type === 'call' && item.outbound) ? (
            <Flex align="center" gap="xs" data-testid="label">
              <Badge
                variant="dot"
                radius="sm"
                color="dark"
                data-testid="value"
                size="md"
              >
                {item.type === 'call'
                  ? item.status
                  : formatStatusToLabel(item.content)}
              </Badge>
            </Flex>
          ) : null}
        </Flex>
      </Flex>

      {(item.type === 'message' || item.type === 'note') && (
        <Spoiler maxHeight={100} showLabel="Show more" hideLabel="Hide" mt="md">
          <Flex
            className={`${classes.bubble} ${classes.note}`}
            style={{ flex: 1 }}
            direction="column"
            justify="flex-start"
            align="flex-start"
            gap="xs"
            w="100%"
          >
            <Text
              mt={item.type === 'note' ? 'sm' : undefined}
              dangerouslySetInnerHTML={{ __html: item.content }}
            />

            {item.type === 'message' && item.attachments && (
              <Flex gap="sm" wrap="wrap">
                {item.attachments?.map((attachment, id) => (
                  <AttachmentCard {...attachment} key={id} />
                ))}
              </Flex>
            )}
          </Flex>
        </Spoiler>
      )}

      {item.type === 'call' &&
        (item.recording_url || item.content.length > 0) && (
          <Box key="recording">
            {item.analysis && (
              <Paper p="md" radius="xs" bg="violet.0" mt="sm">
                <Flex align="center" justify="space-between">
                  <Box style={{ flex: 1 }}>
                    <Text style={{ whiteSpace: 'pre-line' }}>
                      {item.analysis}
                    </Text>
                  </Box>

                  <Badge
                    color="violet"
                    pl={3}
                    variant="outline"
                    leftSection={
                      <ThemeIcon size="xs" variant="transparent">
                        <IconSparkles size={10} />
                      </ThemeIcon>
                    }
                  >
                    AI
                  </Badge>
                </Flex>
              </Paper>
            )}
            {item.recording_url && (
              <Flex key="recording-url" gap="xs" direction="column" my="xl">
                <Paper bg="transparent" mb="xs">
                  <audio
                    className="audio_player"
                    src={item.recording_url}
                    controls
                    style={{
                      width: '100%',
                      height: 40,
                    }}
                  />
                </Paper>

                <Divider />
              </Flex>
            )}

            <Spoiler
              key="spoiler"
              maxHeight={100}
              showLabel="Show more"
              hideLabel="Hide"
              mt="xl"
            >
              <Flex
                className={`${classes.bubble} ${classes.note}`}
                style={{ flex: 1 }}
                direction="column"
                justify="flex-start"
                align="flex-start"
                gap="xs"
                w="100%"
              >
                <Flex direction="column" gap="lg" justify="center">
                  {item.content.map((content) => (
                    <Grid key={content.content}>
                      <Grid.Col span={1}>
                        <Flex
                          h="100%"
                          align="center"
                          justify="center"
                          direction="column"
                          gap={2}
                        >
                          {content.outbound && (
                            <Badge size="xs" variant="outline" color="gray">
                              Lead
                            </Badge>
                          )}

                          <Text c="dimmed" size="xs">
                            {formatDurationInterval(
                              new Date(item.created_at),
                              new Date(content.timestamp),
                            )}
                          </Text>
                        </Flex>
                      </Grid.Col>
                      <Grid.Col span={10}>
                        <Paper p="xs" withBorder>
                          {content.content}
                        </Paper>
                      </Grid.Col>
                    </Grid>
                  ))}
                </Flex>
              </Flex>
            </Spoiler>
          </Box>
        )}
    </Paper>
  );
}
