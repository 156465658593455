import { Title, Flex, Divider } from '@mantine/core';
import { Organisation } from 'shared/db';
import {
  useBlacklistDomain,
  useBlacklistEmail,
  useBlacklistedDomains,
  useBlacklistedEmails,
  useDeleteBlacklistedDomain,
  useDeleteBlacklistedEmail,
} from 'fe/queries';
import { CreateDeleteList } from 'fe/ui/shared';

interface OrganisationEmailVerificationProps {
  organisation: Organisation;
}

export function EmailBlacklist({
  organisation,
}: OrganisationEmailVerificationProps) {
  const organisationId = organisation.id;
  // const { data: domain, isLoading } = useAuthenticatedDomain(organisationId);
  const { data: blacklistedDomains } = useBlacklistedDomains(organisationId);
  const { data: blacklistedEmails } = useBlacklistedEmails(organisationId);
  // const { mutateAsync: enableEmailParsing, isPending: isLoadingParsing } =
  //   useEnableEmailParsing();

  const { mutateAsync: blacklistDomain, isPending: isBlacklistingDomain } =
    useBlacklistDomain(organisationId);
  const { mutateAsync: blacklistEmail, isPending: isBlacklistingEmail } =
    useBlacklistEmail(organisationId);
  //
  const {
    mutateAsync: deleteBlacklistedDomain,
    isPending: isDeletingBlacklistedDomain,
  } = useDeleteBlacklistedDomain();
  const {
    mutateAsync: deleteBlacklistedEmail,
    isPending: isDeletingBlacklistedEmail,
  } = useDeleteBlacklistedEmail();

  // if (!domain) return null;

  // if (isLoading) {
  //   return (
  //     <Flex justify="center" align="center" style={{ height: '400px' }}>
  //       <Loader />
  //     </Flex>
  //   );
  // }

  return (
    <Flex w="100%" justify="space-between" gap="sm" direction="column">
      <Title mt="xl" order={2}>
        Blacklisted emails / domains
      </Title>

      <Divider mb="sm" />

      <Flex w="100%" gap="sm">
        <CreateDeleteList
          data={blacklistedEmails?.map((domain) => domain.email) || []}
          onCreate={blacklistEmail}
          isCreating={isBlacklistingEmail}
          onDeleteClick={(email: string) => {
            const emailToDelete = blacklistedEmails!.find(
              (d) => d.email === email,
            );
            if (emailToDelete) {
              deleteBlacklistedEmail(emailToDelete.id);
            }
          }}
          isDeleting={isDeletingBlacklistedEmail}
          label="Email"
          placeholder="john@food.com"
        />

        <CreateDeleteList
          data={blacklistedDomains?.map((domain) => domain.domain) || []}
          onCreate={blacklistDomain}
          isCreating={isBlacklistingDomain}
          onDeleteClick={(domain: string) => {
            const domainToDelete = blacklistedDomains!.find(
              (d) => d.domain === domain,
            );
            if (domainToDelete) {
              deleteBlacklistedDomain(domainToDelete.id);
            }
          }}
          isDeleting={isDeletingBlacklistedDomain}
          label="Domain"
          placeholder="food.com"
        />
      </Flex>
    </Flex>
  );
}
