import * as React from 'react';
import { Outlet, createRootRoute, useLocation } from '@tanstack/react-router';
import { TanStackRouterDevtools } from '@tanstack/router-devtools';
import { Layout } from 'fe/feature/layout';
import { AuthWrapper } from 'fe/feature/auth';
import { HelmetProvider } from 'react-helmet-async';

export const Route = createRootRoute({
  component: RootComponent
});

function RootComponent() {
  const location = useLocation();
  console.log('location', location.pathname);
  const publicRoutes = [
    '/terms-of-service',
    '/privacy-policy',
    '/service-agreement'
  ];
  if (publicRoutes.includes(location.pathname)) {
    return (
      <React.Fragment>
        <HelmetProvider>
          <AuthWrapper>
            <Layout noScrolling noPadding hideProfileGuard noMenu noHeader>
              <Outlet />
            </Layout>
          </AuthWrapper>
          {import.meta.env.MODE === 'development' && <TanStackRouterDevtools />}
        </HelmetProvider>
      </React.Fragment>
    );
  }
  return (
    <React.Fragment>
      <HelmetProvider>
        <AuthWrapper>
          <Layout noScrolling noPadding>
            <Outlet />
          </Layout>
        </AuthWrapper>
        {import.meta.env.MODE === 'development' && <TanStackRouterDevtools />}
      </HelmetProvider>
    </React.Fragment>
  );
}
