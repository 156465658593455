import { ApiEndpoint } from './types';
import { z } from 'zod';

const analyzeLeadInboxRouteSchema = z.object({
  lead_id: z.number(),
});

export const analyzeLeadInboxEndpoint: ApiEndpoint<
  typeof analyzeLeadInboxRouteSchema
> = {
  apiUrl: '/lead/:lead_id/analyzeInbox',
  clientUrl: ({ lead_id }) => `/lead/${lead_id}/analyzeInbox`,
  method: 'POST',
};

const postLeadAiFeedbackRouteSchema = z.object({
  suggestion_id: z.number(),
});

export const postLeadAiFeedbackRouteParams = z.object({
  approved: z.boolean(),
});

export const postLeadAiFeedbackEndpoint: ApiEndpoint<
  typeof postLeadAiFeedbackRouteSchema,
  typeof postLeadAiFeedbackRouteParams
> = {
  apiUrl: '/lead_ai_suggestions/:suggestion_id/feedback',
  clientUrl: ({ suggestion_id }) =>
    `/lead_ai_suggestions/${suggestion_id}/feedback`,
  method: 'POST',
  schema: postLeadAiFeedbackRouteParams,
};
