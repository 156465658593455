import { SupabaseClient } from '@supabase/supabase-js';
import { v4 as uuidv4 } from 'uuid';

export interface OrganisationContract {
  id: number;
  label: string;
  filename: string;
  organisation_id: number;
  buyer_name: string | null;
}

export async function uploadOrganisationContract(
  supabase: SupabaseClient,
  organisation_id: number,
  file: File,
) {
  const uuid = uuidv4();
  const contractFilename = `private/${organisation_id}/${uuid}/${file.name}`;
  const { error } = await supabase.storage
    .from('contracts')
    .upload(contractFilename, file, {
      upsert: true,
    });

  if (error) {
    console.log('error', error);
    throw new Error("Couldn't upload organisation contract");
  }
  return contractFilename;
}
