import { IconPhone } from '@tabler/icons-react';
import {
  Box,
  Flex,
  Text,
  ThemeIcon,
  Grid,
  Divider,
  Tooltip,
  ActionIcon,
} from '@mantine/core';
import { AudioPlayer } from 'fe/ui/shared';
import { IconPhoneOff, IconPhoneOutgoing, IconX } from '@tabler/icons-react';
import {
  formatLastMessageDate,
  formatPhoneNumberToNational,
} from 'shared/formatter';
import { LeadSearchInput } from './LeadSearchInput';
import { useAssignCall, useDismissCall, usePhoneStore } from 'fe/queries';

interface UnassignedCallProps {
  id: number;
  from: string;
  to: string;
  created_at: string;
  status: string;
  analysis: string | null;
  recording_url: string | null;
  outbound: boolean;
  hideDivider?: boolean;
}

export function UnassignedCall({
  id,
  from,
  to,
  created_at,
  outbound,
  recording_url,
  hideDivider = false,
}: UnassignedCallProps) {
  const { mutate: assignCall } = useAssignCall();
  const { mutate: dismissCall } = useDismissCall();
  const onSelect = (value: string) => {
    assignCall({
      contact_id: parseInt(value),
      call_id: id,
    });
  };
  const makeCall = usePhoneStore((s) => s.makeCall);

  let title: React.ReactNode;
  let icon: React.ReactNode;
  if (outbound) {
    title = 'Outbound call to ' + formatPhoneNumberToNational(to);
    icon = (
      <ThemeIcon variant="light" size="lg" color="blue" radius="xl">
        <IconPhoneOutgoing size="1.125rem" />
      </ThemeIcon>
    );
  } else {
    title = 'Inbound call from ' + formatPhoneNumberToNational(from);
    icon = (
      <ThemeIcon variant="light" size="lg" color="red" radius="xl">
        <IconPhoneOff size="1rem" />
      </ThemeIcon>
    );
  }

  const handleDismiss = () => {
    dismissCall({
      call_id: id,
    });
  };

  return (
    <Box py="md" w="100%">
      <Grid mb="xs">
        <Grid.Col span={2}>
          <Flex align="center" h="100%">
            {icon}
          </Flex>
        </Grid.Col>
        <Grid.Col span={7}>
          <Flex align="center" gap="xs">
            <Text fz="sm" fw={600}>
              {title}
            </Text>
          </Flex>
          <Text size="sm">{formatLastMessageDate(created_at)}</Text>
        </Grid.Col>

        <Grid.Col span={3}>
          <Flex align="center" h="100%" gap="xs">
            <Tooltip label="Call back" withinPortal>
              <ActionIcon
                variant="light"
                size="lg"
                color="blue"
                radius="xl"
                onClick={() =>
                  makeCall({
                    name: formatPhoneNumberToNational(to) || '',
                    number: to,
                  })
                }
              >
                <IconPhone size="1rem" />
              </ActionIcon>
            </Tooltip>

            <Tooltip label="Dismiss" withinPortal>
              <ActionIcon
                variant="light"
                size="lg"
                color="gray"
                radius="xl"
                onClick={handleDismiss}
              >
                <IconX size="1rem" />
              </ActionIcon>
            </Tooltip>
          </Flex>
        </Grid.Col>
      </Grid>

      {recording_url && <AudioPlayer url={recording_url} />}

      <LeadSearchInput onSelect={onSelect} />

      {!hideDivider && <Divider mt="xl" />}
    </Box>
  );
}
