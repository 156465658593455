import { createFileRoute } from '@tanstack/react-router';
import { Profile } from 'fe/feature/inbox';
import { Helmet } from 'react-helmet-async';
import { useLeadProfilePage } from 'fe/queries';

export const Route = createFileRoute('/leads/$leadId')({
  component: Index
});

function Index() {
  const { leadId } = Route.useParams();
  const { data: lead } = useLeadProfilePage({
    lead_id: Number(leadId)
  });
  return (
    <>
      <Helmet>
        {lead && (
          <title>Lead - {lead?.address.street_address} - Hey Rafi</title>
        )}
      </Helmet>

      <Profile leadId={Number(leadId)} />
    </>
  );
}
