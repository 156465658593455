/* eslint-disable */

// @ts-nocheck

// noinspection JSUnusedGlobalSymbols

// This file was automatically generated by TanStack Router.
// You should NOT make any changes in this file as it will be overwritten.
// Additionally, you should also exclude this file from your linter and/or formatter to prevent it from being checked or modified.

import { createFileRoute } from '@tanstack/react-router'

// Import Routes

import { Route as rootRoute } from './routes/__root'
import { Route as TermsOfServiceImport } from './routes/terms-of-service'
import { Route as ServiceAgreementImport } from './routes/service-agreement'
import { Route as PrivacyPolicyImport } from './routes/privacy-policy'
import { Route as LeadsImport } from './routes/leads'
import { Route as InsightsImport } from './routes/insights'
import { Route as IndexImport } from './routes/index'
import { Route as OfferIndexImport } from './routes/offer/index'
import { Route as SettingsLayoutImport } from './routes/settings/_layout'
import { Route as LeadsLeadIdImport } from './routes/leads.$leadId'
import { Route as CampaignsCampaignIdImport } from './routes/campaigns/$campaignId'
import { Route as SettingsLayoutIndexImport } from './routes/settings/_layout.index'
import { Route as SettingsLayoutWorkflowsImport } from './routes/settings/_layout.workflows'
import { Route as SettingsLayoutRepairsImport } from './routes/settings/_layout.repairs'
import { Route as SettingsLayoutPricingImport } from './routes/settings/_layout.pricing'
import { Route as SettingsLayoutPhoneImport } from './routes/settings/_layout.phone'
import { Route as SettingsLayoutMembersImport } from './routes/settings/_layout.members'
import { Route as SettingsLayoutEmailImport } from './routes/settings/_layout.email'
import { Route as SettingsLayoutCrmImport } from './routes/settings/_layout.crm'
import { Route as SettingsLayoutContractsImport } from './routes/settings/_layout.contracts'
import { Route as SettingsLayoutCompsImport } from './routes/settings/_layout.comps'
import { Route as OfferZipcodeStreetaddressIndexImport } from './routes/offer/$zip_code.$street_address/index'

// Create Virtual Routes

const SettingsImport = createFileRoute('/settings')()

// Create/Update Routes

const SettingsRoute = SettingsImport.update({
  id: '/settings',
  path: '/settings',
  getParentRoute: () => rootRoute,
} as any)

const TermsOfServiceRoute = TermsOfServiceImport.update({
  id: '/terms-of-service',
  path: '/terms-of-service',
  getParentRoute: () => rootRoute,
} as any)

const ServiceAgreementRoute = ServiceAgreementImport.update({
  id: '/service-agreement',
  path: '/service-agreement',
  getParentRoute: () => rootRoute,
} as any)

const PrivacyPolicyRoute = PrivacyPolicyImport.update({
  id: '/privacy-policy',
  path: '/privacy-policy',
  getParentRoute: () => rootRoute,
} as any)

const LeadsRoute = LeadsImport.update({
  id: '/leads',
  path: '/leads',
  getParentRoute: () => rootRoute,
} as any)

const InsightsRoute = InsightsImport.update({
  id: '/insights',
  path: '/insights',
  getParentRoute: () => rootRoute,
} as any)

const IndexRoute = IndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => rootRoute,
} as any)

const OfferIndexRoute = OfferIndexImport.update({
  id: '/offer/',
  path: '/offer/',
  getParentRoute: () => rootRoute,
} as any)

const SettingsLayoutRoute = SettingsLayoutImport.update({
  id: '/_layout',
  getParentRoute: () => SettingsRoute,
} as any)

const LeadsLeadIdRoute = LeadsLeadIdImport.update({
  id: '/$leadId',
  path: '/$leadId',
  getParentRoute: () => LeadsRoute,
} as any)

const CampaignsCampaignIdRoute = CampaignsCampaignIdImport.update({
  id: '/campaigns/$campaignId',
  path: '/campaigns/$campaignId',
  getParentRoute: () => rootRoute,
} as any)

const SettingsLayoutIndexRoute = SettingsLayoutIndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => SettingsLayoutRoute,
} as any)

const SettingsLayoutWorkflowsRoute = SettingsLayoutWorkflowsImport.update({
  id: '/workflows',
  path: '/workflows',
  getParentRoute: () => SettingsLayoutRoute,
} as any)

const SettingsLayoutRepairsRoute = SettingsLayoutRepairsImport.update({
  id: '/repairs',
  path: '/repairs',
  getParentRoute: () => SettingsLayoutRoute,
} as any)

const SettingsLayoutPricingRoute = SettingsLayoutPricingImport.update({
  id: '/pricing',
  path: '/pricing',
  getParentRoute: () => SettingsLayoutRoute,
} as any)

const SettingsLayoutPhoneRoute = SettingsLayoutPhoneImport.update({
  id: '/phone',
  path: '/phone',
  getParentRoute: () => SettingsLayoutRoute,
} as any)

const SettingsLayoutMembersRoute = SettingsLayoutMembersImport.update({
  id: '/members',
  path: '/members',
  getParentRoute: () => SettingsLayoutRoute,
} as any)

const SettingsLayoutEmailRoute = SettingsLayoutEmailImport.update({
  id: '/email',
  path: '/email',
  getParentRoute: () => SettingsLayoutRoute,
} as any)

const SettingsLayoutCrmRoute = SettingsLayoutCrmImport.update({
  id: '/crm',
  path: '/crm',
  getParentRoute: () => SettingsLayoutRoute,
} as any)

const SettingsLayoutContractsRoute = SettingsLayoutContractsImport.update({
  id: '/contracts',
  path: '/contracts',
  getParentRoute: () => SettingsLayoutRoute,
} as any)

const SettingsLayoutCompsRoute = SettingsLayoutCompsImport.update({
  id: '/comps',
  path: '/comps',
  getParentRoute: () => SettingsLayoutRoute,
} as any)

const OfferZipcodeStreetaddressIndexRoute =
  OfferZipcodeStreetaddressIndexImport.update({
    id: '/offer/$zip_code/$street_address/',
    path: '/offer/$zip_code/$street_address/',
    getParentRoute: () => rootRoute,
  } as any)

// Populate the FileRoutesByPath interface

declare module '@tanstack/react-router' {
  interface FileRoutesByPath {
    '/': {
      id: '/'
      path: '/'
      fullPath: '/'
      preLoaderRoute: typeof IndexImport
      parentRoute: typeof rootRoute
    }
    '/insights': {
      id: '/insights'
      path: '/insights'
      fullPath: '/insights'
      preLoaderRoute: typeof InsightsImport
      parentRoute: typeof rootRoute
    }
    '/leads': {
      id: '/leads'
      path: '/leads'
      fullPath: '/leads'
      preLoaderRoute: typeof LeadsImport
      parentRoute: typeof rootRoute
    }
    '/privacy-policy': {
      id: '/privacy-policy'
      path: '/privacy-policy'
      fullPath: '/privacy-policy'
      preLoaderRoute: typeof PrivacyPolicyImport
      parentRoute: typeof rootRoute
    }
    '/service-agreement': {
      id: '/service-agreement'
      path: '/service-agreement'
      fullPath: '/service-agreement'
      preLoaderRoute: typeof ServiceAgreementImport
      parentRoute: typeof rootRoute
    }
    '/terms-of-service': {
      id: '/terms-of-service'
      path: '/terms-of-service'
      fullPath: '/terms-of-service'
      preLoaderRoute: typeof TermsOfServiceImport
      parentRoute: typeof rootRoute
    }
    '/campaigns/$campaignId': {
      id: '/campaigns/$campaignId'
      path: '/campaigns/$campaignId'
      fullPath: '/campaigns/$campaignId'
      preLoaderRoute: typeof CampaignsCampaignIdImport
      parentRoute: typeof rootRoute
    }
    '/leads/$leadId': {
      id: '/leads/$leadId'
      path: '/$leadId'
      fullPath: '/leads/$leadId'
      preLoaderRoute: typeof LeadsLeadIdImport
      parentRoute: typeof LeadsImport
    }
    '/settings': {
      id: '/settings'
      path: '/settings'
      fullPath: '/settings'
      preLoaderRoute: typeof SettingsImport
      parentRoute: typeof rootRoute
    }
    '/settings/_layout': {
      id: '/settings/_layout'
      path: '/settings'
      fullPath: '/settings'
      preLoaderRoute: typeof SettingsLayoutImport
      parentRoute: typeof SettingsRoute
    }
    '/offer/': {
      id: '/offer/'
      path: '/offer'
      fullPath: '/offer'
      preLoaderRoute: typeof OfferIndexImport
      parentRoute: typeof rootRoute
    }
    '/settings/_layout/comps': {
      id: '/settings/_layout/comps'
      path: '/comps'
      fullPath: '/settings/comps'
      preLoaderRoute: typeof SettingsLayoutCompsImport
      parentRoute: typeof SettingsLayoutImport
    }
    '/settings/_layout/contracts': {
      id: '/settings/_layout/contracts'
      path: '/contracts'
      fullPath: '/settings/contracts'
      preLoaderRoute: typeof SettingsLayoutContractsImport
      parentRoute: typeof SettingsLayoutImport
    }
    '/settings/_layout/crm': {
      id: '/settings/_layout/crm'
      path: '/crm'
      fullPath: '/settings/crm'
      preLoaderRoute: typeof SettingsLayoutCrmImport
      parentRoute: typeof SettingsLayoutImport
    }
    '/settings/_layout/email': {
      id: '/settings/_layout/email'
      path: '/email'
      fullPath: '/settings/email'
      preLoaderRoute: typeof SettingsLayoutEmailImport
      parentRoute: typeof SettingsLayoutImport
    }
    '/settings/_layout/members': {
      id: '/settings/_layout/members'
      path: '/members'
      fullPath: '/settings/members'
      preLoaderRoute: typeof SettingsLayoutMembersImport
      parentRoute: typeof SettingsLayoutImport
    }
    '/settings/_layout/phone': {
      id: '/settings/_layout/phone'
      path: '/phone'
      fullPath: '/settings/phone'
      preLoaderRoute: typeof SettingsLayoutPhoneImport
      parentRoute: typeof SettingsLayoutImport
    }
    '/settings/_layout/pricing': {
      id: '/settings/_layout/pricing'
      path: '/pricing'
      fullPath: '/settings/pricing'
      preLoaderRoute: typeof SettingsLayoutPricingImport
      parentRoute: typeof SettingsLayoutImport
    }
    '/settings/_layout/repairs': {
      id: '/settings/_layout/repairs'
      path: '/repairs'
      fullPath: '/settings/repairs'
      preLoaderRoute: typeof SettingsLayoutRepairsImport
      parentRoute: typeof SettingsLayoutImport
    }
    '/settings/_layout/workflows': {
      id: '/settings/_layout/workflows'
      path: '/workflows'
      fullPath: '/settings/workflows'
      preLoaderRoute: typeof SettingsLayoutWorkflowsImport
      parentRoute: typeof SettingsLayoutImport
    }
    '/settings/_layout/': {
      id: '/settings/_layout/'
      path: '/'
      fullPath: '/settings/'
      preLoaderRoute: typeof SettingsLayoutIndexImport
      parentRoute: typeof SettingsLayoutImport
    }
    '/offer/$zip_code/$street_address/': {
      id: '/offer/$zip_code/$street_address/'
      path: '/offer/$zip_code/$street_address'
      fullPath: '/offer/$zip_code/$street_address'
      preLoaderRoute: typeof OfferZipcodeStreetaddressIndexImport
      parentRoute: typeof rootRoute
    }
  }
}

// Create and export the route tree

interface LeadsRouteChildren {
  LeadsLeadIdRoute: typeof LeadsLeadIdRoute
}

const LeadsRouteChildren: LeadsRouteChildren = {
  LeadsLeadIdRoute: LeadsLeadIdRoute,
}

const LeadsRouteWithChildren = LeadsRoute._addFileChildren(LeadsRouteChildren)

interface SettingsLayoutRouteChildren {
  SettingsLayoutCompsRoute: typeof SettingsLayoutCompsRoute
  SettingsLayoutContractsRoute: typeof SettingsLayoutContractsRoute
  SettingsLayoutCrmRoute: typeof SettingsLayoutCrmRoute
  SettingsLayoutEmailRoute: typeof SettingsLayoutEmailRoute
  SettingsLayoutMembersRoute: typeof SettingsLayoutMembersRoute
  SettingsLayoutPhoneRoute: typeof SettingsLayoutPhoneRoute
  SettingsLayoutPricingRoute: typeof SettingsLayoutPricingRoute
  SettingsLayoutRepairsRoute: typeof SettingsLayoutRepairsRoute
  SettingsLayoutWorkflowsRoute: typeof SettingsLayoutWorkflowsRoute
  SettingsLayoutIndexRoute: typeof SettingsLayoutIndexRoute
}

const SettingsLayoutRouteChildren: SettingsLayoutRouteChildren = {
  SettingsLayoutCompsRoute: SettingsLayoutCompsRoute,
  SettingsLayoutContractsRoute: SettingsLayoutContractsRoute,
  SettingsLayoutCrmRoute: SettingsLayoutCrmRoute,
  SettingsLayoutEmailRoute: SettingsLayoutEmailRoute,
  SettingsLayoutMembersRoute: SettingsLayoutMembersRoute,
  SettingsLayoutPhoneRoute: SettingsLayoutPhoneRoute,
  SettingsLayoutPricingRoute: SettingsLayoutPricingRoute,
  SettingsLayoutRepairsRoute: SettingsLayoutRepairsRoute,
  SettingsLayoutWorkflowsRoute: SettingsLayoutWorkflowsRoute,
  SettingsLayoutIndexRoute: SettingsLayoutIndexRoute,
}

const SettingsLayoutRouteWithChildren = SettingsLayoutRoute._addFileChildren(
  SettingsLayoutRouteChildren,
)

interface SettingsRouteChildren {
  SettingsLayoutRoute: typeof SettingsLayoutRouteWithChildren
}

const SettingsRouteChildren: SettingsRouteChildren = {
  SettingsLayoutRoute: SettingsLayoutRouteWithChildren,
}

const SettingsRouteWithChildren = SettingsRoute._addFileChildren(
  SettingsRouteChildren,
)

export interface FileRoutesByFullPath {
  '/': typeof IndexRoute
  '/insights': typeof InsightsRoute
  '/leads': typeof LeadsRouteWithChildren
  '/privacy-policy': typeof PrivacyPolicyRoute
  '/service-agreement': typeof ServiceAgreementRoute
  '/terms-of-service': typeof TermsOfServiceRoute
  '/campaigns/$campaignId': typeof CampaignsCampaignIdRoute
  '/leads/$leadId': typeof LeadsLeadIdRoute
  '/settings': typeof SettingsLayoutRouteWithChildren
  '/offer': typeof OfferIndexRoute
  '/settings/comps': typeof SettingsLayoutCompsRoute
  '/settings/contracts': typeof SettingsLayoutContractsRoute
  '/settings/crm': typeof SettingsLayoutCrmRoute
  '/settings/email': typeof SettingsLayoutEmailRoute
  '/settings/members': typeof SettingsLayoutMembersRoute
  '/settings/phone': typeof SettingsLayoutPhoneRoute
  '/settings/pricing': typeof SettingsLayoutPricingRoute
  '/settings/repairs': typeof SettingsLayoutRepairsRoute
  '/settings/workflows': typeof SettingsLayoutWorkflowsRoute
  '/settings/': typeof SettingsLayoutIndexRoute
  '/offer/$zip_code/$street_address': typeof OfferZipcodeStreetaddressIndexRoute
}

export interface FileRoutesByTo {
  '/': typeof IndexRoute
  '/insights': typeof InsightsRoute
  '/leads': typeof LeadsRouteWithChildren
  '/privacy-policy': typeof PrivacyPolicyRoute
  '/service-agreement': typeof ServiceAgreementRoute
  '/terms-of-service': typeof TermsOfServiceRoute
  '/campaigns/$campaignId': typeof CampaignsCampaignIdRoute
  '/leads/$leadId': typeof LeadsLeadIdRoute
  '/settings': typeof SettingsLayoutIndexRoute
  '/offer': typeof OfferIndexRoute
  '/settings/comps': typeof SettingsLayoutCompsRoute
  '/settings/contracts': typeof SettingsLayoutContractsRoute
  '/settings/crm': typeof SettingsLayoutCrmRoute
  '/settings/email': typeof SettingsLayoutEmailRoute
  '/settings/members': typeof SettingsLayoutMembersRoute
  '/settings/phone': typeof SettingsLayoutPhoneRoute
  '/settings/pricing': typeof SettingsLayoutPricingRoute
  '/settings/repairs': typeof SettingsLayoutRepairsRoute
  '/settings/workflows': typeof SettingsLayoutWorkflowsRoute
  '/offer/$zip_code/$street_address': typeof OfferZipcodeStreetaddressIndexRoute
}

export interface FileRoutesById {
  __root__: typeof rootRoute
  '/': typeof IndexRoute
  '/insights': typeof InsightsRoute
  '/leads': typeof LeadsRouteWithChildren
  '/privacy-policy': typeof PrivacyPolicyRoute
  '/service-agreement': typeof ServiceAgreementRoute
  '/terms-of-service': typeof TermsOfServiceRoute
  '/campaigns/$campaignId': typeof CampaignsCampaignIdRoute
  '/leads/$leadId': typeof LeadsLeadIdRoute
  '/settings': typeof SettingsRouteWithChildren
  '/settings/_layout': typeof SettingsLayoutRouteWithChildren
  '/offer/': typeof OfferIndexRoute
  '/settings/_layout/comps': typeof SettingsLayoutCompsRoute
  '/settings/_layout/contracts': typeof SettingsLayoutContractsRoute
  '/settings/_layout/crm': typeof SettingsLayoutCrmRoute
  '/settings/_layout/email': typeof SettingsLayoutEmailRoute
  '/settings/_layout/members': typeof SettingsLayoutMembersRoute
  '/settings/_layout/phone': typeof SettingsLayoutPhoneRoute
  '/settings/_layout/pricing': typeof SettingsLayoutPricingRoute
  '/settings/_layout/repairs': typeof SettingsLayoutRepairsRoute
  '/settings/_layout/workflows': typeof SettingsLayoutWorkflowsRoute
  '/settings/_layout/': typeof SettingsLayoutIndexRoute
  '/offer/$zip_code/$street_address/': typeof OfferZipcodeStreetaddressIndexRoute
}

export interface FileRouteTypes {
  fileRoutesByFullPath: FileRoutesByFullPath
  fullPaths:
    | '/'
    | '/insights'
    | '/leads'
    | '/privacy-policy'
    | '/service-agreement'
    | '/terms-of-service'
    | '/campaigns/$campaignId'
    | '/leads/$leadId'
    | '/settings'
    | '/offer'
    | '/settings/comps'
    | '/settings/contracts'
    | '/settings/crm'
    | '/settings/email'
    | '/settings/members'
    | '/settings/phone'
    | '/settings/pricing'
    | '/settings/repairs'
    | '/settings/workflows'
    | '/settings/'
    | '/offer/$zip_code/$street_address'
  fileRoutesByTo: FileRoutesByTo
  to:
    | '/'
    | '/insights'
    | '/leads'
    | '/privacy-policy'
    | '/service-agreement'
    | '/terms-of-service'
    | '/campaigns/$campaignId'
    | '/leads/$leadId'
    | '/settings'
    | '/offer'
    | '/settings/comps'
    | '/settings/contracts'
    | '/settings/crm'
    | '/settings/email'
    | '/settings/members'
    | '/settings/phone'
    | '/settings/pricing'
    | '/settings/repairs'
    | '/settings/workflows'
    | '/offer/$zip_code/$street_address'
  id:
    | '__root__'
    | '/'
    | '/insights'
    | '/leads'
    | '/privacy-policy'
    | '/service-agreement'
    | '/terms-of-service'
    | '/campaigns/$campaignId'
    | '/leads/$leadId'
    | '/settings'
    | '/settings/_layout'
    | '/offer/'
    | '/settings/_layout/comps'
    | '/settings/_layout/contracts'
    | '/settings/_layout/crm'
    | '/settings/_layout/email'
    | '/settings/_layout/members'
    | '/settings/_layout/phone'
    | '/settings/_layout/pricing'
    | '/settings/_layout/repairs'
    | '/settings/_layout/workflows'
    | '/settings/_layout/'
    | '/offer/$zip_code/$street_address/'
  fileRoutesById: FileRoutesById
}

export interface RootRouteChildren {
  IndexRoute: typeof IndexRoute
  InsightsRoute: typeof InsightsRoute
  LeadsRoute: typeof LeadsRouteWithChildren
  PrivacyPolicyRoute: typeof PrivacyPolicyRoute
  ServiceAgreementRoute: typeof ServiceAgreementRoute
  TermsOfServiceRoute: typeof TermsOfServiceRoute
  CampaignsCampaignIdRoute: typeof CampaignsCampaignIdRoute
  SettingsRoute: typeof SettingsRouteWithChildren
  OfferIndexRoute: typeof OfferIndexRoute
  OfferZipcodeStreetaddressIndexRoute: typeof OfferZipcodeStreetaddressIndexRoute
}

const rootRouteChildren: RootRouteChildren = {
  IndexRoute: IndexRoute,
  InsightsRoute: InsightsRoute,
  LeadsRoute: LeadsRouteWithChildren,
  PrivacyPolicyRoute: PrivacyPolicyRoute,
  ServiceAgreementRoute: ServiceAgreementRoute,
  TermsOfServiceRoute: TermsOfServiceRoute,
  CampaignsCampaignIdRoute: CampaignsCampaignIdRoute,
  SettingsRoute: SettingsRouteWithChildren,
  OfferIndexRoute: OfferIndexRoute,
  OfferZipcodeStreetaddressIndexRoute: OfferZipcodeStreetaddressIndexRoute,
}

export const routeTree = rootRoute
  ._addFileChildren(rootRouteChildren)
  ._addFileTypes<FileRouteTypes>()

/* ROUTE_MANIFEST_START
{
  "routes": {
    "__root__": {
      "filePath": "__root.tsx",
      "children": [
        "/",
        "/insights",
        "/leads",
        "/privacy-policy",
        "/service-agreement",
        "/terms-of-service",
        "/campaigns/$campaignId",
        "/settings",
        "/offer/",
        "/offer/$zip_code/$street_address/"
      ]
    },
    "/": {
      "filePath": "index.tsx"
    },
    "/insights": {
      "filePath": "insights.tsx"
    },
    "/leads": {
      "filePath": "leads.tsx",
      "children": [
        "/leads/$leadId"
      ]
    },
    "/privacy-policy": {
      "filePath": "privacy-policy.tsx"
    },
    "/service-agreement": {
      "filePath": "service-agreement.tsx"
    },
    "/terms-of-service": {
      "filePath": "terms-of-service.tsx"
    },
    "/campaigns/$campaignId": {
      "filePath": "campaigns/$campaignId.tsx"
    },
    "/leads/$leadId": {
      "filePath": "leads.$leadId.tsx",
      "parent": "/leads"
    },
    "/settings": {
      "filePath": "settings",
      "children": [
        "/settings/_layout"
      ]
    },
    "/settings/_layout": {
      "filePath": "settings/_layout.tsx",
      "parent": "/settings",
      "children": [
        "/settings/_layout/comps",
        "/settings/_layout/contracts",
        "/settings/_layout/crm",
        "/settings/_layout/email",
        "/settings/_layout/members",
        "/settings/_layout/phone",
        "/settings/_layout/pricing",
        "/settings/_layout/repairs",
        "/settings/_layout/workflows",
        "/settings/_layout/"
      ]
    },
    "/offer/": {
      "filePath": "offer/index.tsx"
    },
    "/settings/_layout/comps": {
      "filePath": "settings/_layout.comps.tsx",
      "parent": "/settings/_layout"
    },
    "/settings/_layout/contracts": {
      "filePath": "settings/_layout.contracts.tsx",
      "parent": "/settings/_layout"
    },
    "/settings/_layout/crm": {
      "filePath": "settings/_layout.crm.tsx",
      "parent": "/settings/_layout"
    },
    "/settings/_layout/email": {
      "filePath": "settings/_layout.email.tsx",
      "parent": "/settings/_layout"
    },
    "/settings/_layout/members": {
      "filePath": "settings/_layout.members.tsx",
      "parent": "/settings/_layout"
    },
    "/settings/_layout/phone": {
      "filePath": "settings/_layout.phone.tsx",
      "parent": "/settings/_layout"
    },
    "/settings/_layout/pricing": {
      "filePath": "settings/_layout.pricing.tsx",
      "parent": "/settings/_layout"
    },
    "/settings/_layout/repairs": {
      "filePath": "settings/_layout.repairs.tsx",
      "parent": "/settings/_layout"
    },
    "/settings/_layout/workflows": {
      "filePath": "settings/_layout.workflows.tsx",
      "parent": "/settings/_layout"
    },
    "/settings/_layout/": {
      "filePath": "settings/_layout.index.tsx",
      "parent": "/settings/_layout"
    },
    "/offer/$zip_code/$street_address/": {
      "filePath": "offer/$zip_code.$street_address/index.tsx"
    }
  }
}
ROUTE_MANIFEST_END */
