import { Paper } from '@mantine/core';

export const AudioPlayer = ({ url }: { url: string }) => {
  return (
    <Paper bg="transparent" mb="xs">
      <audio
        className="audio_player"
        src={url}
        controls
        style={{
          width: '100%',
          height: 40,
        }}
      />
    </Paper>
  );
};
