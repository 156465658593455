import { useEffect, useState } from 'react';
import { Button, Group, Modal, Text, Paper, Flex } from '@mantine/core';
import {
  IconPhoneOff,
  IconMicrophone,
  IconKeyboard,
  IconMicrophoneOff,
} from '@tabler/icons-react';
import { useInitPhoneStore, usePhoneStore } from 'fe/queries';
import { useInterval } from '@mantine/hooks';
import { useShallow } from 'zustand/react/shallow';
import { formatDuration, formatPhoneNumberToNational } from 'shared/formatter';

export const CallInterface = () => {
  useInitPhoneStore(); // no need to do anything

  const [seconds, setSeconds] = useState(0);
  const interval = useInterval(() => setSeconds((s) => s + 1), 1000);
  const duration = formatDuration(seconds);

  const {
    call,
    incomingCall,
    calleeName,
    isMuted,
    toggleMute,
    endCall,
    rejectIncomingCall,
    acceptIncomingCall,
  } = usePhoneStore(
    useShallow((s) => ({
      call: s.call,
      incomingCall: s.incomingCall,
      calleeName: s.calleeName,
      isMuted: s.isMuted,
      toggleMute: s.toggleMute,
      endCall: s.endCall,
      pressDigit: s.pressDigit,
      rejectIncomingCall: s.rejectIncomingCall,
      acceptIncomingCall: s.acceptIncomingCall,
    })),
  );

  useEffect(() => {
    if (call) {
      interval.start();
    } else {
      interval.stop();
      setSeconds(0);
    }
  }, [call, incomingCall]);

  const [keyboardOpen, setKeyboardOpen] = useState(false);

  const toggleKeyboard = () => setKeyboardOpen((prev) => !prev);

  if (!call && !incomingCall) return null;

  return (
    <Paper
      pos="absolute"
      style={{
        zIndex: 1000,
        right: '16px',
        bottom: '16px',
        padding: '16px',
        width: '300px',
        background: '#1e1e1e',
        color: 'white',
        borderRadius: '12px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      {/* INCOMING CALL */}
      {incomingCall && (
        <div style={{ marginBottom: '16px', textAlign: 'center' }}>
          <Text weight={700}>
            {formatPhoneNumberToNational(incomingCall.parameters['From'])}
          </Text>
          <Text size="sm" color="dimmed">
            Incoming call - {duration}
          </Text>

          <Flex gap="xs" mt="xs">
            <Button color="green" onClick={acceptIncomingCall}>
              Accept
            </Button>
            <Button color="red" variant="outline" onClick={rejectIncomingCall}>
              Reject
            </Button>
          </Flex>
        </div>
      )}
      {/* CALL IN PROGRESS */}
      {call && (
        <div style={{ marginBottom: '16px', textAlign: 'center' }}>
          <Text weight={700}>{calleeName}</Text>
          <Text size="sm" color="dimmed">
            Call in progress - {duration}
          </Text>

          <Group spacing="xl" mt="xs">
            <Button
              color={isMuted ? 'yellow' : 'gray'}
              onClick={toggleMute}
              variant="subtle"
            >
              {isMuted ? (
                <IconMicrophoneOff size={24} />
              ) : (
                <IconMicrophone size={24} />
              )}
            </Button>
            <Button color="red" onClick={endCall} variant="filled">
              <IconPhoneOff size={24} />
            </Button>
            <Button color="blue" onClick={toggleKeyboard} variant="subtle">
              <IconKeyboard size={24} />
            </Button>
          </Group>
        </div>
      )}

      {/* CALL ACTIONS */}

      <Modal
        opened={keyboardOpen}
        onClose={toggleKeyboard}
        title="Numeric Keypad"
        centered
      >
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: 'repeat(3, 1fr)',
            gap: '12px',
          }}
        >
          {[...Array(9)].map((_, i) => (
            <Button key={i} variant="light">
              {i + 1}
            </Button>
          ))}
          <Button variant="light">✻</Button>
          <Button variant="light">0</Button>
          <Button variant="light">#</Button>
        </div>
      </Modal>
    </Paper>
  );
};
