import { Location, Property } from 'shared/db';
import { Buffer } from 'buffer';
import { Properties } from 'be/db';
import { Selectable } from 'kysely';
import { intervalToDuration } from 'date-fns';

export const formatListedDate = (date: string | Date | null) =>
  date ? new Date(date).toLocaleDateString('en-US') : null;

export const formatPrice = (price: number) => {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });
  return formatter.format(price);
};

export const formatUIPrice = (price: number) => {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: 0,
  });
  return formatter.format(price);
};

export const formatZipCode = (zip: number): string => {
  return zip.toString().padStart(5, '0');
};

export const formatContractPrice = (price: number) => {
  return price.toLocaleString(undefined, { minimumFractionDigits: 2 });
};

export const formatPropertyAddress = (
  property: Property | Selectable<Properties>,
  location: Location,
) => {
  return `${property.street_address}, ${location.city}, ${location.state_id} ${location.zip_code}`;
};

export const formatStreetAddress = (
  street_address: string,
  location: Location,
) =>
  `${street_address}, ${location.city}, ${location.state_id} ${location.zip_code}`;

export const formatAddress = (
  street_address: string,
  location: {
    city: string | null;
    state_id: string | null;
    zip: number;
  },
) =>
  `${street_address}, ${location.city}, ${location.state_id} ${location.zip}`;

export function formatPhoneNumber(phoneNumber: string | null) {
  if (!phoneNumber || !phoneNumber.replace) {
    return '';
  }
  return phoneNumber.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
}

export function getLabelFromUrl(url: string) {
  return url.split('/').pop();
}

export const getLatLong = (coordinatesString: string) => {
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  try {
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const wkx = require('wkx');
    const wkbBuffer = Buffer.from(coordinatesString, 'hex');

    // Parse the WKB Buffer to a GeoJSON object
    const geometry = (wkx as any).Geometry.parse(wkbBuffer).toGeoJSON();

    // Extract the latitude and longitude
    return {
      longitude: geometry.coordinates[0],
      latitude: geometry.coordinates[1],
    };
  } catch (e) {
    console.log('failed to get lat long: ', e);
    return null;
    // throw new Error('Failed to get lat long');
  }
};

export const formatDuration = (seconds: number) => {
  const duration = intervalToDuration({ start: 0, end: seconds * 1000 });
  const zeroPad = (num: number) => String(num).padStart(2, '0');

  return [duration.hours, duration.minutes || 0, duration.seconds || 0]
    .filter((num) => num !== undefined)
    .map(zeroPad)
    .join(':');
};

export const formatDurationInterval = (start: Date, end: Date) => {
  const duration = intervalToDuration({ start, end });
  const zeroPad = (num: number) => String(num).padStart(2, '0');

  return [duration.hours, duration.minutes || 0, duration.seconds || 0]
    .filter((num) => num !== undefined)
    .map(zeroPad)
    .join(':');
};
