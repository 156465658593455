import { useDocumentTitle } from '@mantine/hooks';
import { createFileRoute } from '@tanstack/react-router';
import { OfferToolPage } from '../../../pages/offer/OfferToolPage';

export const Route = createFileRoute('/offer/$zip_code/$street_address/')({
  component: RouteComponent,
  params: {
    parse: (params) => {
      return {
        zip_code: params.zip_code,
        street_address: decodeURIComponent(params.street_address)
      };
    }
  },
  stringifyParams: (params) => {
    return {
      zip_code: params.zip_code,
      street_address: encodeURIComponent(params.street_address)
    };
  }
});

function RouteComponent() {
  const { street_address } = Route.useParams();
  useDocumentTitle(`${street_address} - Hey Rafi`);
  console.log('lol');
  return <OfferToolPage />;
}
