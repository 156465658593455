import { createFileRoute } from '@tanstack/react-router';
import { PricingStrategySettings } from 'fe/feature/organisation-settings';

export const Route = createFileRoute('/settings/_layout/pricing')({
  component: RouteComponent
});

function RouteComponent() {
  return <PricingStrategySettings />;
}
