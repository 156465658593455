import {
  Accordion,
  Button,
  Container,
  Flex,
  Grid,
  Group,
  Loader,
  MultiSelect,
  NumberInput,
  ScrollArea,
  Select,
  Switch,
  TextInput,
  Text,
  Checkbox,
  Paper,
} from '@mantine/core';
import { useEffect, useState } from 'react';
import { Campaign, CampaignFilters, LeaseType, PropertyType } from 'shared/db';
import { useForm } from '@mantine/form';
import { useDebouncedValue } from '@mantine/hooks';
import { notifications } from '@mantine/notifications';
import {
  CampaignFilterForm,
  useAvailableListingsByCampaignFilters,
  useCampaignFilters,
  useGenerateCampaignProperties,
  useIsAdmin,
  useUpdateCampaign,
  useUpdateCampaignFilters,
} from 'fe/queries';
import { getCampaignFiltersInputs } from './CampaignFilters.utils';
import { mapBooleanToString, mapStringToBoolean } from 'shared/formatter';

interface CampaignFiltersProps {
  campaign: Campaign;
}

interface AccordionLabelProps {
  label: string;
  Icon: JSX.Element;
  description: string;
}
function AccordionLabel({ label, Icon, description }: AccordionLabelProps) {
  return (
    <Group noWrap>
      {Icon}
      <div>
        <Text>{label}</Text>
        <Text size="sm" color="dimmed" weight={400}>
          {description}
        </Text>
      </div>
    </Group>
  );
}

function initForm(data?: CampaignFilters | null) {
  return {
    ...data,
    time_on_market: data?.time_on_market || undefined,
    property_types: data?.property_types || [],
    min_beds: data?.min_beds || undefined,
    max_beds: data?.max_beds || undefined,
    min_baths: data?.min_baths || undefined,
    max_baths: data?.max_baths || undefined,
    min_price: data?.min_price || undefined,
    max_price: data?.max_price || undefined,
    min_sqft: data?.min_sqft || undefined,
    max_sqft: data?.max_sqft || undefined,
    min_lot_sqft: data?.min_lot_sqft || undefined,
    max_lot_sqft: data?.max_lot_sqft || undefined,
    min_year_built: data?.min_year_built || undefined,
    max_year_built: data?.max_year_built || undefined,
    max_hoa: data?.max_hoa || undefined,
    community_55: mapBooleanToString(data?.community_55),
    equity_purchase: mapBooleanToString(data?.equity_purchase),
    club_membership: mapBooleanToString(data?.club_membership),
    lease: data?.lease || '',
    keywords: (data?.keywords || []).join(', '),
    must_have_garage: mapBooleanToString(data?.must_have_garage) || 'false',
    property_condition: data?.property_condition || [],
  };
}

interface CampaignFiltersProps {
  campaign: Campaign;
  hideRefresh?: boolean;
  onSuccess?: () => void;
}

export function CampaignFiltersView({
  campaign,
  hideRefresh = false,
  onSuccess,
}: CampaignFiltersProps) {
  const [isLoadingCount, setIsLoadingCount] = useState(false);
  const { mutateAsync: updateCampaignFilters, isPending: isLoading } =
    useUpdateCampaignFilters();
  const { mutateAsync: updateCampaign, isPending: isUpdatingCampaign } =
    useUpdateCampaign();
  const { mutateAsync: generateProperties, isPending: isGenerating } =
    useGenerateCampaignProperties();
  const { data: isAdmin } = useIsAdmin();
  const { data, isLoading: isLoadingFilters } = useCampaignFilters(
    Number(campaign.id),
  );
  const form = useForm<CampaignFilterForm>({
    initialValues: initForm(data),
  });
  const [debouncedFormValues] = useDebouncedValue(form.values, 2000);

  const { data: availableListings, isLoading: isLoadingAvailableListings } =
    useAvailableListingsByCampaignFilters({
      campaign_id: campaign.id,
      filters: {
        ...debouncedFormValues,
        must_have_garage:
          mapStringToBoolean(debouncedFormValues.must_have_garage) || false,
      } as any,
    });

  const onSubmit = form.onSubmit(async (values) => {
    await updateCampaignFilters({
      campaign_id: Number(campaign.id),
      property_types: (values.property_types as PropertyType[]) || [],
      time_on_market: values.time_on_market || null,
      min_beds: values.min_beds || null,
      max_beds: values.max_beds || null,
      min_baths: values.min_baths || null,
      max_baths: values.max_baths || null,
      min_price: values.min_price || null,
      max_price: values.max_price || null,
      min_sqft: values.min_sqft || null,
      max_sqft: values.max_sqft || null,
      min_lot_sqft: values.min_lot_sqft || null,
      max_lot_sqft: values.max_lot_sqft || null,
      min_year_built: values.min_year_built || null,
      max_year_built: values.max_year_built || null,
      max_hoa: values.max_hoa || null,
      lease: (values.lease as LeaseType) || null,
      keywords: values.keywords
        ? values.keywords.split(',').map((keyword) => keyword.trim())
        : null,
      community_55: mapStringToBoolean(values.community_55),
      equity_purchase: mapStringToBoolean(values.equity_purchase),
      club_membership: mapStringToBoolean(values.club_membership),
      must_have_garage: mapStringToBoolean(values.must_have_garage) || false,
      property_condition: values.property_condition || null,
    });

    await updateCampaign({
      ...campaign,
      campaign_id: Number(campaign.id),
      zip_codes: undefined,
    });

    notifications.show({
      title: 'Campaign filters updated',
      message: `Filters for the campaign ${campaign.name} were successfully updated`,
    });

    onSuccess && onSuccess();
  });

  useEffect(() => {
    console.log(debouncedFormValues, form.values);
    if (JSON.stringify(debouncedFormValues) == JSON.stringify(form.values)) {
      setIsLoadingCount(false);
    } else {
      setIsLoadingCount(true);
    }
  }, [form.values, debouncedFormValues]);

  useEffect(() => {
    if (isLoadingFilters) return;
    form.setValues(initForm(data));
  }, [data]);

  return (
    <div>
      <Container
        size="xs"
        h="100%"
        style={{
          display: 'flex',
          flexDirection: 'column',
          position: 'absolute',
          width: '100%',
          height: '85%',
          right: 0,
          left: 0,
        }}
      >
        <Flex direction="column" mb="lg">
          <Paper radius="md" p="xs">
            <Flex align="left" gap="xs">
              <Text size="xs">
                {campaign.smart
                  ? 'Properties that can be analyzed:'
                  : 'Available properties based on your filters:'}
              </Text>
              {isLoadingAvailableListings || isLoadingCount ? (
                <Loader size={16} />
              ) : (
                <Text fw={600} size="xs">
                  {availableListings} *
                </Text>
              )}
            </Flex>

            <Text c="dimmed" size="xs" mt="xs">
              * Number is an approximate based on the counties you have selected
              and may not be accurate.
            </Text>
          </Paper>
        </Flex>

        <ScrollArea>
          <form onSubmit={onSubmit} style={{ width: '100%' }}>
            <Accordion>
              {getCampaignFiltersInputs(campaign).map((section) => (
                <Accordion.Item value={section.id} key={section.id}>
                  <Accordion.Control>
                    <AccordionLabel
                      label={section.label}
                      Icon={section.icon}
                      description={section.description}
                    />
                  </Accordion.Control>
                  <Accordion.Panel>
                    <Grid>
                      {section.filters.map((filter) => {
                        const inputProps = form.getInputProps(filter.key);
                        if (filter.type === 'number') {
                          return (
                            <Grid.Col span={6} key={filter.key}>
                              <NumberInput
                                key={filter.key}
                                label={filter.label}
                                {...inputProps}
                              />
                            </Grid.Col>
                          );
                        } else if (filter.type === 'multiSelect') {
                          return (
                            <Grid.Col span={6} key={filter.key}>
                              <MultiSelect
                                key={filter.key}
                                label={filter.label}
                                data={filter.data || []}
                                {...inputProps}
                              />
                            </Grid.Col>
                          );
                        } else if (filter.type === 'select') {
                          return (
                            <Grid.Col span={6} key={filter.key}>
                              <Select
                                key={filter.key}
                                label={filter.label}
                                data={filter.data || []}
                                {...inputProps}
                              />
                            </Grid.Col>
                          );
                        } else if (filter.type === 'switch') {
                          return (
                            <Grid.Col span={6} key={filter.key}>
                              <Switch
                                key={filter.key}
                                label={filter.label}
                                defaultChecked={inputProps.value}
                                {...inputProps}
                              />
                            </Grid.Col>
                          );
                        } else if (filter.type === 'text') {
                          return (
                            <Grid.Col span={12} key={filter.key}>
                              <TextInput
                                key={filter.key}
                                label={filter.label}
                                placeholder={filter.placeholder}
                                {...inputProps}
                              />
                            </Grid.Col>
                          );
                        } else if (filter.type === 'checkboxGroup') {
                          return (
                            <Checkbox.Group
                              key={filter.key}
                              label={filter.label}
                              description={filter.description}
                              {...inputProps}
                            >
                              <Group mt="xs">
                                {filter.options.map((checkbox) => (
                                  <Checkbox
                                    value={checkbox.value}
                                    key={checkbox.value}
                                    label={checkbox.label}
                                    description={checkbox.description}
                                  />
                                ))}
                              </Group>
                            </Checkbox.Group>
                          );
                        }
                        return null;
                      })}
                    </Grid>
                  </Accordion.Panel>
                </Accordion.Item>
              ))}
            </Accordion>

            <Group position="right">
              <Button
                mt="lg"
                loading={isLoading || isUpdatingCampaign}
                type="submit"
              >
                Update filters
              </Button>

              {!hideRefresh && isAdmin && (
                <>
                  <Button
                    mt="lg"
                    variant="outline"
                    loading={isGenerating}
                    onClick={() => generateProperties(Number(campaign.id))}
                  >
                    Refresh results
                  </Button>
                </>
              )}
            </Group>
          </form>
        </ScrollArea>
      </Container>
    </div>
  );
}
