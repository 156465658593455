import { trpc } from '../setup';

export const useSetUpPhoneAccount = () => {
  const utils = trpc.useUtils();
  return trpc.organization.twilio.setUpAccount.useMutation({
    onSuccess: (data, params) => {
      utils.organization.twilio.isPhoneEnabled.setData(
        { organization_id: params.organization_id },
        () => data,
      );
    },
  });
};

export const useIsPhoneEnabled =
  trpc.organization.twilio.isPhoneEnabled.useQuery;
