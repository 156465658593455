import { createFileRoute } from '@tanstack/react-router';
import { CompCriteriaSettings } from 'fe/feature/organisation-settings';

export const Route = createFileRoute('/settings/_layout/comps')({
  component: RouteComponent
});

function RouteComponent() {
  return <CompCriteriaSettings />;
}
