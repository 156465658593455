import { Flex, Tabs, Box } from '@mantine/core';
import classes from './HomePage.module.css';
import { useCampaign, useListings, useOrganisationStore } from 'fe/queries';
import { NoOrganisationsErrorMessage } from './../../modules/NoOrganisationsErrorMessage/NoOrganisationsErrorMessage';
import { PageLoader } from './../../components/PageLoader';
import { Route } from './../../routes/campaigns/$campaignId';
import { Campaign } from 'shared/db';
import { OffersTable } from 'fe/feature/campaign';
import { ARV_Page } from 'fe/feature/arv';
import { CampaignSideview } from 'fe/feature/campaign';
import { skipToken } from '@tanstack/react-query';

export function HomePage() {
  const { organisation, isLoading: isLoadingOrganisation } =
    useOrganisationStore();
  const { campaignId: selectedCampaignId } = Route.useParams();
  const { data: selectedCampaign, isLoading: isLoadingCampaign } = useCampaign(
    selectedCampaignId as string
  );

  const isLoading = isLoadingCampaign || isLoadingOrganisation;

  const { data: listings, isLoading: isLoadingListings } = useListings(
    selectedCampaign
      ? {
          campaign_id: selectedCampaign?.id
        }
      : skipToken
  );

  if (selectedCampaign?.smart) {
    return <ARV_Page campaign={selectedCampaign as unknown as Campaign} />;
  }

  if (isLoading || !selectedCampaign) return <PageLoader />;

  if (!organisation) return <NoOrganisationsErrorMessage />;

  return (
    <Flex p="xs" w="100%">
      <Flex direction="column" gap="md" style={{ flex: 1 }}>
        <Tabs
          classNames={{
            root: classes.tabsRoot,
            panel: classes.tabsPanel
          }}
          defaultValue="inbox"
          keepMounted={false}
        >
          <Tabs.List>
            <Tabs.Tab value="inbox">Offers</Tabs.Tab>
          </Tabs.List>

          <Tabs.Panel value="inbox" pt="xs">
            <div className={classes.inboxWrapper}>
              <OffersTable
                data={listings || []}
                isLoading={isLoadingListings}
                campaign={selectedCampaign!}
              />
            </div>
          </Tabs.Panel>
        </Tabs>
      </Flex>
      <Box w={400}>
        {selectedCampaign && <CampaignSideview campaign={selectedCampaign} />}
      </Box>
    </Flex>
  );
}
