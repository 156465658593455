import { Select } from '@mantine/core';
import { useState } from 'react';
import { useOrganisationStore, useSearchLeads } from 'fe/queries';
import { skipToken } from '@tanstack/react-query';
import { useDebouncedValue } from '@mantine/hooks';

interface LeadSearchInputProps {
  onSelect: (value: string) => void;
}

export function LeadSearchInput({ onSelect }: LeadSearchInputProps) {
  const { organisation } = useOrganisationStore();
  const [searchValue, onSearchChange] = useState('');
  const [debouncedSearchValue] = useDebouncedValue(searchValue, 200);
  const shouldSearch = searchValue && searchValue.length > 2;
  const params = {
    organization_id: organisation?.id ?? 0,
    query: debouncedSearchValue,
  };
  const { data: leads } = useSearchLeads(shouldSearch ? params : skipToken);

  return (
    <Select
      label="Assigned lead"
      placeholder="Street address, zip code or name"
      searchable
      nothingFound="No leads found, try a different term"
      data={
        leads?.map((lead) => ({
          value: lead.contact_id.toString(),
          label: `${lead.street_address}, ${lead.zip_code}`,
        })) || []
      }
      onSearchChange={onSearchChange}
      searchValue={searchValue}
      onChange={onSelect}
    />
  );
}
