import { Title, Divider } from '@mantine/core';
import { createFileRoute } from '@tanstack/react-router';
import { SignatureEditor } from '../../components/SignatureEditor';
import { useOrganisationStore } from 'fe/queries';
import { useDocumentTitle } from '@mantine/hooks';
import { DomainsList, EmailBlacklist } from 'fe/feature/organisation-settings';

export const Route = createFileRoute('/settings/_layout/email')({
  component: RouteComponent
});

function RouteComponent() {
  useDocumentTitle('Email - Settings - Hey Rafi');
  const { organisation } = useOrganisationStore();
  if (!organisation) return null;
  return (
    <>
      <DomainsList />
      <EmailBlacklist organisation={organisation} />;
      <Title order={2} mt="xl">
        Email Signature
      </Title>
      <Divider my="sm" />
      <SignatureEditor organisation={organisation} />
    </>
  );
}
