import { Box, Button, Popover, TextInput, Flex } from '@mantine/core';
import { useForm, zodResolver } from '@mantine/form';
import { useOrganisationStore, useStartDomainAuthentication } from 'fe/queries';
import { useState } from 'react';
import { zodUrlWithoutProtocol } from 'shared/validators';
import { z } from 'zod';

export function AddDomainButton() {
  const { organisation } = useOrganisationStore();
  const { mutateAsync: startDomainAuthentication, isPending } =
    useStartDomainAuthentication();
  const [opened, setOpened] = useState(false);

  const schema = z.object({
    domain: zodUrlWithoutProtocol,
  });
  const form = useForm({
    initialValues: {
      domain: '',
    },
    validate: zodResolver(schema),
  });
  const onSubmit = form.onSubmit((values) => {
    if (!organisation) return;
    startDomainAuthentication({
      organization_id: organisation.id,
      domain: values.domain,
    });
    setOpened(false);
  });

  return (
    <Box>
      <Popover
        width={400}
        position="bottom"
        withArrow
        shadow="md"
        opened={opened}
        onChange={setOpened}
      >
        <Popover.Target>
          <Button variant="outline" onClick={() => setOpened((prev) => !prev)}>
            Add domain
          </Button>
        </Popover.Target>
        <Popover.Dropdown>
          <form onSubmit={onSubmit}>
            <Flex direction="column" gap="md">
              <TextInput
                label="Domain"
                placeholder="myrealestate.com"
                {...form.getInputProps('domain')}
              />
              <Button type="submit" size="sm" loading={isPending}>
                Start authentication process
              </Button>
            </Flex>
          </form>
        </Popover.Dropdown>
      </Popover>
    </Box>
  );
}
