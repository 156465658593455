import { Box, Button, Flex, Input, TextInput } from '@mantine/core';
import { useForm, zodResolver } from '@mantine/form';
import { useUpdateContact } from 'fe/queries';
import { IMaskInput } from 'react-imask';
import { zodPhoneNumberNullable } from 'shared/validators';
import { z } from 'zod';
import { formatPhoneNumberToNational } from 'shared/formatter';

interface ContactFormProps {
  id: number;
  name: string;
  email: string;
  phone: string | null;
  onClose: () => void;
}

export function ContactForm({
  id,
  name,
  email,
  phone,
  onClose,
}: ContactFormProps) {
  const { mutateAsync: update, isPending } = useUpdateContact();

  const schema = z.object({
    name: z.string().min(2, { message: 'Name should have at least 2 letters' }),
    email: z.string().email({ message: 'Invalid email' }),
    phone: zodPhoneNumberNullable,
  });
  const form = useForm({
    initialValues: {
      email,
      name,
      phone: phone ? formatPhoneNumberToNational(phone) : null,
    },
    validate: zodResolver(schema),
  });

  const onFormSubmit = form.onSubmit(async (values) => {
    await update({
      contact_id: id,
      name: values.name,
      emails: [values.email],
      phone: values.phone ? [values.phone] : [],
    });
    onClose();
  });

  return (
    <Box>
      <form onSubmit={onFormSubmit}>
        <Flex gap="xs" direction="column">
          <TextInput
            label="Name"
            placeholder="John Doe"
            variant="filled"
            {...form.getInputProps('name')}
          />

          <TextInput
            label="Email"
            placeholder="john@gmail.com"
            variant="filled"
            {...form.getInputProps('email')}
          />

          <Box>
            <Input.Wrapper
              id="phone-input"
              label="Phone"
              {...form.getInputProps('phone')}
            >
              <Input
                id="phone-input"
                variant="filled"
                placeholder="(555) 555-5555"
                component={IMaskInput}
                mask="(000) 000-0000"
                {...form.getInputProps('phone')}
                onAccept={(value) => {
                  form.setFieldValue('phone', value);
                }}
              />
            </Input.Wrapper>
          </Box>

          <Button mt="md" size="xs" type="submit" loading={isPending}>
            SAVE
          </Button>
        </Flex>
      </form>
    </Box>
  );
}
