import { create } from 'zustand';
import { useOrganisationStore } from '../organisation/useOrganisationStore';
import { useEffect } from 'react';
import { Call, Device } from '@twilio/voice-sdk';
import { useDeviceToken } from '../inbox/useDeviceToken';
import { notifications } from '@mantine/notifications';
import { skipToken } from '@tanstack/react-query';
import { useShallow } from 'zustand/react/shallow';
import { useProfile, useUser } from '../shared/useUser';

type ExtraCallInfo = {
  name: string;
  startTime: Date;
};
interface MakeCallParams {
  number: string;
  name: string;
  contactId?: number;
}

interface PhoneState {
  userId: string;
  setUserId: (item: string) => void;
  device: Device | null;
  setDevice: (item: Device) => void;
  call: Call | null;
  setCall: (item: Call | null) => void;

  incomingCall: Call | null;
  setIncomingCall: (item: Call | null) => void;

  acceptIncomingCall: () => void;
  rejectIncomingCall: () => void;

  extraCallInfo: ExtraCallInfo | null;
  setExtraCallInfo: (item: ExtraCallInfo | null) => void;
  callDuration: number;
  setCallDuration: (item: number) => void;
  calleeName: string;
  isMuted: boolean;
  //
  makeCall: ({ number, name }: MakeCallParams) => void;
  toggleMute: () => void;
  endCall: () => void;
  pressDigit: (digit: string) => void;
}

export const usePhoneStore = create<PhoneState>()((set, get) => {
  return {
    userId: '',
    setUserId: (userId) => set({ userId }),
    device: null,
    setDevice: (device) => set({ device }),
    call: null,
    setCall: (call) => set({ call }),
    incomingCall: null,
    setIncomingCall: (incomingCall) => set({ incomingCall }),
    extraCallInfo: null,
    setExtraCallInfo: (extraCallInfo) => set({ extraCallInfo }),
    callDuration: 0,
    setCallDuration: (callDuration) => set({ callDuration }),
    calleeName: '',
    isMuted: false,
    //
    makeCall: async ({ number, name, contactId }) => {
      const device = get().device;
      if (!device) {
        notifications.show({
          title: 'Device not initialized',
          message: 'Click the button again',
        });
        return;
      }
      const connectionParams: Record<string, string> = {
        To: number,
        rafiCreatedBy: get().userId,
      };
      if (contactId) {
        connectionParams['rafiContactId'] = contactId.toString();
      }
      const call = await device.connect({
        params: connectionParams,
      });
      set({ call, calleeName: name });
      // events
      call.on('disconnect', () => {
        console.log('Call has been disconnected');
        set({ call: null, isMuted: false });
      });
      call.on('reject', () => {
        console.log('Call has been rejected');
        set({ call: null, isMuted: false });
      });
    },
    toggleMute: () => {
      const call = get().call;
      if (call) {
        const newVal = !call.isMuted();
        call.mute(newVal);
        set({ isMuted: newVal });
      }
    },
    endCall: () => {
      const call = get().call;
      if (call) {
        call.disconnect();
        set({ call: null, isMuted: false });
      }
    },
    pressDigit: (digit) => {
      const call = get().call;
      if (call) {
        call.sendDigits(digit);
      }
    },
    //
    acceptIncomingCall: () => {
      const incomingCall = get().incomingCall;
      if (incomingCall) {
        incomingCall.accept();
        set({ incomingCall: null, call: incomingCall });

        incomingCall.on('disconnect', () => {
          console.log('Call has been disconnected');
          set({ call: null, isMuted: false });
        });
      }
    },
    rejectIncomingCall: () => {
      const incomingCall = get().incomingCall;
      if (incomingCall) {
        incomingCall.reject();
        set({ incomingCall: null });
      }
    },
  };
});

export const useInitPhoneStore = () => {
  const { organisation } = useOrganisationStore();
  const { data: user } = useUser();

  const { device, userId, setDevice, endCall, setUserId, setIncomingCall } =
    usePhoneStore(
      useShallow((s) => ({
        device: s.device,
        setDevice: s.setDevice,
        endCall: s.endCall,
        setUserId: s.setUserId,
        userId: s.userId,
        incomingCall: s.incomingCall,
        setIncomingCall: s.setIncomingCall,
      })),
    );
  const { data: token, refetch: refetchToken } = useDeviceToken(
    organisation?.id && user
      ? {
          organization_id: organisation.id,
        }
      : skipToken,
  );

  const { data: profile } = useProfile(user ? undefined : skipToken);
  const profileId = profile?.profile?.id;

  // Fetch Twilio token and initialize the device
  const initializeDevice = async () => {
    console.log('initializeDevice');
    if (!token || device) return;
    ``;
    try {
      const newDevice = new Device(token.token, {
        codecPreferences: [Call.Codec.Opus, Call.Codec.PCMU],
      });

      newDevice.on('registered', function () {
        console.log('Twilio.Device Ready to make and receive calls!');
      });

      newDevice.on('ready', () =>
        console.log('Device ready to make and receive calls'),
      );
      newDevice.on('error', (error) =>
        console.log(`Device error: ${error.message}`),
      );
      newDevice.on('disconnect', () => {
        console.log('Device disconnected');
        endCall();
      });

      newDevice.on('tokenWillExpire', () => {
        refetchToken();
      });

      newDevice.on('incoming', (connection) => {
        console.log('incoming', connection);
        setIncomingCall(connection);
        notifications.show({
          title: 'Incoming call...',
          message: connection.parameters.From,
        });
        connection.on('cancel', () => {
          notifications.show({
            title: 'Incoming call canceled',
            message: connection.parameters.From,
          });
          setIncomingCall(null);
        });
      });

      newDevice.register();

      setDevice(newDevice);
    } catch (error) {
      console.error('Failed to initialize device');
    }
  };

  useEffect(() => {
    initializeDevice();
  }, [token]);

  useEffect(() => {
    if (profileId && userId !== profileId) {
      setUserId(profileId);
    }
  }, [profileId]);
};

export const isCallingEnabled = () => {
  const { device, call } = usePhoneStore((s) => ({
    device: s.device,
    call: s.call,
  }));
  return Boolean(device && !call);
};
