import { Button, Group, Paper, Select, Textarea } from '@mantine/core';
import { isNotEmpty, useForm } from '@mantine/form';
import { IconDeviceMobileMessage } from '@tabler/icons-react';
import { useContacts, useLeadProfileStore, useSendSms } from 'fe/queries';
import { formatPhoneNumberToNational } from 'shared/formatter';

interface SmsFormProps {
  lead_id: number;
}

export function SmsForm({ lead_id }: SmsFormProps) {
  const { data: contacts } = useContacts({ lead_id });
  const { phoneRecipient, toggleSmsForm } = useLeadProfileStore((state) => ({
    phoneRecipient: state.phoneRecipient,
    toggleSmsForm: state.toggleSmsForm,
  }));
  const { mutateAsync: send, isPending } = useSendSms(lead_id);

  const form = useForm({
    initialValues: {
      body: '',
    },
    validate: {
      body: isNotEmpty(),
    },
  });

  // Not optimized at all but should be acceptable since the number of contacts is limited
  const options: { value: string; label: string }[] = [];
  contacts?.forEach((contact) => {
    contact.phones.forEach((phone) => {
      options.push({
        value: phone,
        label: `${contact.name} • ${formatPhoneNumberToNational(phone)}`,
      });
    });
  });

  const onSubmit = form.onSubmit(async (values) => {
    if (!phoneRecipient) return;
    await send({
      phone: phoneRecipient,
      body: values.body,
    });
    form.reset();
  });

  return (
    <Paper withBorder p="sm">
      <form onSubmit={onSubmit}>
        <Select
          icon={<IconDeviceMobileMessage />}
          placeholder="Pick one"
          value={phoneRecipient}
          variant="unstyled"
          data={options || []}
        />
        <Textarea
          variant="filled"
          placeholder="Content of your sms"
          withAsterisk
          minRows={4}
          size="lg"
          mt="sm"
          {...form.getInputProps('body')}
        />
        <Group mt="md">
          <Button type="submit" disabled={!phoneRecipient} loading={isPending}>
            Send SMS
          </Button>
          <Button onClick={() => toggleSmsForm()} variant="default">
            Cancel
          </Button>
        </Group>
      </form>
    </Paper>
  );
}
