import { useDocumentTitle } from '@mantine/hooks';
import { createFileRoute } from '@tanstack/react-router';

import { InsightsPage } from 'fe/feature/insights';

export const Route = createFileRoute('/insights')({
  component: Index
});

function Index() {
  useDocumentTitle('Insights - Hey Rafi');

  return <InsightsPage />;
}
