import { differenceInCalendarDays, format, isToday } from 'date-fns';

export const formatLastMessageDate = (date: string) => {
  const dateObj = new Date(date);
  if (isToday(dateObj)) {
    return dateObj.toLocaleTimeString(undefined, {
      hour: '2-digit',
      minute: '2-digit',
    });
  }

  const difference = differenceInCalendarDays(new Date(), dateObj);
  return `${difference}d ago`;
};

export const formatDateToLocale = (date: Date) => {
  const dateObj = new Date(date);
  return dateObj.toLocaleDateString(undefined);
};

export const formatActivityDate = (date: string) => {
  return format(date, "MMM d, yyyy 'at' hh:mm a 'GMT'");
};
