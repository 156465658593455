import {
  Divider,
  Flex,
  ScrollArea,
  SegmentedControl,
  Select,
} from '@mantine/core';
import { OutreachInsights } from './OutreachInsights/OutreachInsights';
import { DatePickerInput } from '@mantine/dates';
import { ResponseInsights } from './ResponseInsights/ResponseInsights';
import {
  useCampaigns,
  useInsightsStore,
  useOrganisationStore,
} from 'fe/queries';

export function InsightsPage() {
  const { organisation } = useOrganisationStore();
  const { data: campaigns } = useCampaigns(organisation?.id);

  const {
    campaignId,
    setCampaignId,
    dateRange,
    setDateRange,
    granularity,
    setGranularity,
  } = useInsightsStore((state) => ({
    campaignId: state.campaignId,
    setCampaignId: state.setCampaignId,
    dateRange: state.dateRange,
    setDateRange: state.setDateRange,
    granularity: state.granularity,
    setGranularity: state.setGranularity,
  }));

  return (
    <ScrollArea style={{ height: '100vh', width: '100vW' }}>
      <Flex px="xl" py="sm" w="100%" gap="xl" direction="column">
        <Flex gap="sm" align="flex-end">
          <Select
            value={String(campaignId)}
            label="Select campaign"
            placeholder="Pick one"
            onChange={setCampaignId}
            data={[
              { value: '', label: 'All campaigns' },
              ...(campaigns || []).map((campaign) => ({
                label: campaign.name,
                value: String(campaign.id),
              })),
            ]}
          />

          <DatePickerInput
            w={400}
            value={dateRange}
            onChange={([startDate, endDate]) =>
              setDateRange(startDate, endDate)
            }
            label="Range"
            placeholder="Date input"
            type="range"
          />

          <SegmentedControl
            style={{ display: 'none' }}
            value={granularity}
            onChange={setGranularity}
            data={[
              { label: 'Overall', value: 'Overall' },
              { label: 'After initial email', value: 'Initial' },
              { label: 'After first follow up', value: 'Follow up' },
            ]}
          />
        </Flex>

        <Divider orientation="horizontal" />

        <OutreachInsights />

        <ResponseInsights />
      </Flex>
    </ScrollArea>
  );
}
