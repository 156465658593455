import { Accordion, Box } from '@mantine/core';
import { skipToken } from '@tanstack/react-query';
import { useDomains, useOrganisationStore } from 'fe/queries';
import { Typography } from 'fe/ui/shared';
import { AddDomainButton } from './AddDomainButton';
import { DomainItem } from './DomainItem';

export function DomainsList() {
  const { organisation } = useOrganisationStore();
  const { data: domains } = useDomains(
    organisation
      ? {
          organization_id: organisation?.id,
        }
      : skipToken,
  );

  const items = domains?.map((item) => <DomainItem key={item.id} {...item} />);

  return (
    <Box>
      <Typography mb="md">
        These are the domains you've configured to send emails with:
      </Typography>

      <AddDomainButton />

      <Accordion mt="xl" chevronPosition="right" variant="contained">
        {items}
      </Accordion>
    </Box>
  );
}
