import {
  Button,
  Flex,
  NumberInput,
  SegmentedControl,
  TextInput,
  Text,
  Box,
} from '@mantine/core';
import { useForm, zodResolver } from '@mantine/form';
import { useCreateWorkflowStep, useUpdateWorkflowStep } from 'fe/queries';
import {
  OrganisationWorkflow,
  WorkflowStep,
  WorkflowStepType,
} from 'shared/db';
import { WorkflowStepAttachmentForm } from './WorkflowStepAttachmentForm';
import { StyledEditor } from 'fe/ui/shared';
import { z } from 'zod';

interface WorkflowStepFormProps {
  workflow: OrganisationWorkflow;
  workflowStep?: WorkflowStep;
  onSuccess: () => void;
  isFirstStep: boolean;
}

export function WorkflowStepForm({
  workflow,
  workflowStep,
  onSuccess,
  isFirstStep,
}: WorkflowStepFormProps) {
  const { mutateAsync: create, isPending: isCreating } =
    useCreateWorkflowStep();
  const { mutateAsync: update, isPending: isUpdateLoading } =
    useUpdateWorkflowStep();

  const schema = z.object({
    name: z.string().min(1),
    content: z.string().min(1),
    subject: z.string().min(1).optional(),
    wait_days: isFirstStep ? z.number().optional() : z.number().min(1),
  });

  const form = useForm({
    initialValues: {
      content: workflowStep?.content || '',
      name: workflowStep?.name || '',
      subject: workflowStep?.subject || '',
      attachments: workflowStep?.attachments || [],
      wait_days: workflowStep?.wait_days || 0,
    },
    validate: zodResolver(schema),
  });

  const onSubmit = form.onSubmit(async (values) => {
    if (workflowStep) {
      await update({
        workflow_step_id: workflowStep.id,
        ...workflowStep,
        ...values,
      });
    } else {
      await create({
        workflow_id: workflow.id,
        type: WorkflowStepType.EMAIL,
        ...values,
      });
    }
    onSuccess();
  });

  return (
    <div>
      <form onSubmit={onSubmit}>
        <SegmentedControl
          data={[
            { value: WorkflowStepType.EMAIL, label: 'Email' },
            {
              value: WorkflowStepType.SMS,
              label: 'SMS (Coming soon)',
              disabled: true,
            },
          ]}
        />
        <TextInput
          mb="sm"
          withAsterisk
          label="Label"
          placeholder="Initial offer"
          {...form.getInputProps('name')}
        />
        <TextInput
          mb="sm"
          label="Subject (Optional)"
          placeholder="Cash offer - {{ address }}"
          {...form.getInputProps('subject')}
        />
        {!isFirstStep && (
          <NumberInput
            label="Number of days to wait before sending this follow up"
            {...form.getInputProps('wait_days')}
            mb="md"
            min={1}
          />
        )}
        <Text mb="sm" fz="sm">
          Variables available for you to use in the templates:{' '}
          {`{{ name }}, {{ address }}, {{ offerPrice }}, {{ firstName }}`}
        </Text>

        <Box h={300}>
          <StyledEditor
            initialContent={workflowStep?.content}
            buttons={() => (
              <>
                {form.errors['content'] && (
                  <Text color="red" my="xs" fz="xs">
                    {form.errors['content']}
                  </Text>
                )}
                <Flex w="100%" justify="flex-end">
                  <Button
                    type="submit"
                    mt="md"
                    loading={isCreating || isUpdateLoading}
                  >
                    {workflowStep ? 'Update' : 'Create'}
                  </Button>
                </Flex>
              </>
            )}
            {...form.getInputProps('content')}
          />
        </Box>

        {workflowStep && (
          <WorkflowStepAttachmentForm workflowStep={workflowStep} />
        )}
      </form>
    </div>
  );
}
