import { supabase } from './../../utils/database';
import { Button, Container, Flex, Paper, Text, Title } from '@mantine/core';

export function NoOrganisationsErrorMessage() {
  const logout = async () => {
    await supabase.auth.signOut();
  };

  return (
    <Container>
      <Flex h="100%" w="100%" align="center">
        <Paper h="fit-content" p="xl" w="400px">
          <Title order={2}>
            You've successfully logged in but you don't have permission to
            access the TOM tool.
          </Title>
          <Text my="md">
            If you think this is a mistake, please get in contact with us at{' '}
            <a href="mailto:support@heyrafi.com">support@heyrafi.com</a>. Or if
            you logged in with the wrong email, click the button below and log
            in with a different account.
          </Text>
          <Button onClick={logout}>Sign out</Button>
        </Paper>
      </Flex>
    </Container>
  );
}
