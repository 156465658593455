import { useDocumentTitle } from '@mantine/hooks';
import { createFileRoute } from '@tanstack/react-router';
import { ContractManagement } from 'fe/feature/organisation-settings';

export const Route = createFileRoute('/settings/_layout/contracts')({
  component: RouteComponent
});

function RouteComponent() {
  useDocumentTitle('Contracts - Settings - Hey Rafi');
  return <ContractManagement />;
}
