import { skipToken } from '@tanstack/react-query';
import { CampaignFilters } from 'shared/db';
import { useDebouncedValue } from '@mantine/hooks';
import { trpc } from '../setup';

const keys = {
  all: ['availableListings'] as const,
  city: (city: string) => [...keys.all, city],
  campaign: (campaignId: number, filters?: CampaignFilters) => [
    ...keys.all,
    campaignId,
    filters,
  ],
};

export const useAvailableListingsByLocations =
  trpc.organization.campaigns.calculateListings.useQuery;

interface UseAvailableListingsByCampaignFilters {
  campaign_id: number;
  filters?: CampaignFilters;
}

export const useAvailableListingsByCampaignFilters = ({
  campaign_id,
  filters,
}: UseAvailableListingsByCampaignFilters) => {
  const [debounced] = useDebouncedValue(filters, 1000);
  return trpc.organization.campaigns.calculateCampaignListings.useQuery(
    debounced
      ? {
          campaign_id,
          filters: {
            ...debounced,
            keywords: debounced.keywords as any,
          },
        }
      : skipToken,
  );
};
