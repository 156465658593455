import {
  Group,
  Text,
  Accordion,
  Table,
  ThemeIcon,
  Button,
  Flex,
} from '@mantine/core';
import { IconCheck, IconX } from '@tabler/icons-react';
import {
  useDeleteDomain,
  useDomainDnsRecords,
  useValidateDomainAuthentication,
} from 'fe/queries';

interface DomainItemProps {
  id: number;
  name: string;
  validated: boolean;
}

export function DomainItem({ id, name, validated }: DomainItemProps) {
  const { data: records } = useDomainDnsRecords({
    domain_id: id,
  });
  const { mutateAsync: verifyDomain, isPending: isVerifying } =
    useValidateDomainAuthentication();
  const { mutateAsync: deleteDomain, isPending: isDeleting } =
    useDeleteDomain();

  const rows = (records || []).map((element) => (
    <tr key={element.data}>
      <td>{element.type}</td>
      <td>{element.host}</td>
      <td>{element.data}</td>
      <td>{element.valid ? <IconCheck /> : <IconX />}</td>
    </tr>
  ));

  return (
    <Accordion.Item value={String(id)} key={name}>
      <Accordion.Control>
        <Group noWrap>
          {validated ? (
            <ThemeIcon color="green" size="sm" variant="light">
              <IconCheck />
            </ThemeIcon>
          ) : (
            <ThemeIcon color="red" size="sm" variant="light">
              <IconX />
            </ThemeIcon>
          )}
          <div>
            <Text>{name}</Text>
          </div>
        </Group>
      </Accordion.Control>
      <Accordion.Panel>
        <Text size="xs" mb="xl">
          Add DNS records and click "Verify"
        </Text>

        <Table>
          <thead>
            <tr>
              <th>Type</th>
              <th>Host</th>
              <th>Data / Value</th>
              <th>Is valid?</th>
            </tr>
          </thead>
          <tbody>{rows}</tbody>
        </Table>
        <Flex mt="lg" gap="sm">
          <Button
            variant="outline"
            onClick={() => verifyDomain({ domain_id: id })}
            loading={isVerifying}
          >
            Verify
          </Button>
          <Button
            onClick={() => deleteDomain({ domain_id: id })}
            color="red"
            variant="outline"
            loading={isDeleting}
          >
            Delete
          </Button>
        </Flex>
      </Accordion.Panel>
    </Accordion.Item>
  );
}
