import { MRT_TableInstance, MRT_Cell, MRT_Row } from 'mantine-react-table';
import {
  ActionIcon,
  Box,
  Divider,
  Flex,
  Modal,
  Text,
  Table,
  Tooltip,
} from '@mantine/core';
import {
  IconCheck,
  IconDownload,
  IconLink,
  IconMessage,
  IconRefresh,
  IconRocket,
  IconX,
} from '@tabler/icons-react';
import { useDisclosure } from '@mantine/hooks';
import { useState } from 'react';
import {
  useCampaignOffers,
  useCampaigns,
  useFetchOfferEmailContent,
  useOrganisationStore,
  useIsAdmin,
  useGenerateCampaignRecommendations,
  useGenerateCampaignProperties,
  useScheduleAutoOffer,
  useRejectLead,
  useDownloadOfferContract,
} from 'fe/queries';
import { Campaign, CampaignListing, Property } from 'shared/db';
import React from 'react';

interface UseOffersTableActionsReturn {
  enableRowSelection: boolean;
  displayColumnDefOptions: {
    'mrt-row-actions': {
      size: number;
    };
  };
  renderTopToolbarCustomActions:
    | ((props: {
        table: MRT_TableInstance<CampaignListing>;
      }) => React.ReactNode)
    | undefined;
  enableRowActions: boolean;
  renderRowActions?:
    | ((props: {
        cell: MRT_Cell<CampaignListing>;
        row: MRT_Row<CampaignListing>;
        table: MRT_TableInstance<CampaignListing>;
      }) => React.ReactNode)
    | undefined;
  renderBottomToolbarCustomActions:
    | ((props: {
        table: MRT_TableInstance<CampaignListing>;
      }) => React.ReactNode)
    | undefined;
}

export const useOffersTableActions = (
  listings: CampaignListing[],
  campaign: Campaign,
): UseOffersTableActionsReturn => {
  const { organisation } = useOrganisationStore();
  const [opened, { open, close }] = useDisclosure(false);
  const { data } = useCampaignOffers(organisation?.id || 0);
  const { data: isAdmin } = useIsAdmin();
  const { mutateAsync: downloadContract } = useDownloadOfferContract();
  const { mutateAsync: generateProperties, isPending: isGenerating } =
    useGenerateCampaignProperties();
  const {
    mutateAsync: generateRecommendations,
    isPending: isGeneratingRecommendations,
  } = useGenerateCampaignRecommendations();
  const { mutateAsync: rejectLead } = useRejectLead();
  const { mutateAsync: scheduleAutoOffer } = useScheduleAutoOffer(campaign.id);

  const getNumberOfCampaignApprovedOffers = (campaignId: number) =>
    data?.filter((offer) => offer.campaign_id === campaignId).length || 0;

  const isThereOfferError = (property: Property) => {
    if (!property?.agent_email) {
      return 'Agent email not found';
    }
    if (!property?.agent_name) {
      return 'Agent name not found';
    }
    if (!campaign?.workflow_id) {
      return 'Please select a workflow before sending';
    }

    const numApprovedOffers = getNumberOfCampaignApprovedOffers(campaign.id);

    if (numApprovedOffers >= campaign?.daily_offers_limit) {
      return 'You have reached the daily limit';
    }

    return false;
  };

  const { data: campaigns } = useCampaigns(organisation?.id);
  const maxOrgLimit =
    campaigns?.reduce(
      (acc, campaign) => campaign.daily_offers_limit + acc,
      0,
    ) || 0;
  //

  const [emailContent, setEmailContent] = useState('');
  const [emailSubject, setEmailSubject] = useState('');
  const [loadingOffers, setLoadingOffers] = useState<string[]>([]);

  const {
    mutateAsync: fetchOfferEmailContent,
    isPending: loadingEmailContent,
  } = useFetchOfferEmailContent();

  const displayEmailContent = async (CampaignListing: CampaignListing) => {
    const emailContent = await fetchOfferEmailContent({
      ...CampaignListing,
      campaignId: campaign?.id || 0,
    });
    open();
    setEmailContent(emailContent.content);
    setEmailSubject(emailContent.subject);
  };

  return {
    enableRowSelection: false,
    displayColumnDefOptions: {
      'mrt-row-actions': {
        size: 187.5, //make actions column wider
      },
    },
    renderTopToolbarCustomActions: () => {
      return (
        <Flex justify="flex-end" gap="sm" align="center">
          {isAdmin && (
            <>
              <Tooltip
                label="Admin only: Re-run recommendation algorithm"
                withinPortal
              >
                <ActionIcon
                  onClick={() => generateRecommendations(campaign.id)}
                  loading={isGeneratingRecommendations}
                  variant="outline"
                  color="#0169FE"
                >
                  <IconRocket size="1rem" />
                </ActionIcon>
              </Tooltip>
              <Tooltip label="Refresh search results" withinPortal>
                <ActionIcon
                  onClick={() => generateProperties(campaign.id)}
                  loading={isGenerating}
                  variant="outline"
                  color="#0169FE"
                >
                  <IconRefresh size="1rem" />
                </ActionIcon>
              </Tooltip>
              <Divider orientation="vertical" />
            </>
          )}
          <Modal opened={opened} onClose={close} title="Email content" centered>
            <span dangerouslySetInnerHTML={{ __html: emailSubject }} />
            <hr />
            <p dangerouslySetInnerHTML={{ __html: emailContent }} />
          </Modal>
          <Text fw="bold" tt="uppercase" fz="sm">
            Offers sent:{' '}
          </Text>
          <Flex align="center">
            <Text fw="bold">{`${data?.length}`}</Text>/
            {maxOrgLimit - (data?.length || 0)}
          </Flex>
        </Flex>
      );
    },
    renderBottomToolbarCustomActions: () => (
      <Flex align="center" h="100%" style={{ background: 'red' }}>
        <Text>Total properties: {listings?.length || 0}</Text>
      </Flex>
    ),
    enableRowActions: true,
    renderRowActions: ({ row }) => (
      <Box sx={{ display: 'flex', flexWrap: 'nowrap', gap: '8px' }}>
        <Tooltip label="See email" withinPortal>
          <ActionIcon
            variant="outline"
            loading={loadingEmailContent}
            onClick={() => displayEmailContent(row.original)}
          >
            <IconMessage size="1rem" />
          </ActionIcon>
        </Tooltip>
        <Tooltip label="Download contract" withinPortal>
          <ActionIcon
            variant="outline"
            onClick={() =>
              downloadContract({
                campaign_id: campaign.id,
                street_address: row.original.street_address,
                zip_code: row.original.zip_code,
              })
            }
          >
            <IconDownload size="1rem" />
          </ActionIcon>
        </Tooltip>
        <Tooltip label="Send offer to CampaignListing" withinPortal>
          <ActionIcon
            color="blue"
            variant="outline"
            disabled={Boolean(isThereOfferError(row.original))}
            loading={loadingOffers.includes(row.original.street_address)}
            onClick={() => {
              if (
                !campaign.workflow_id ||
                !campaign.contract_id ||
                !row.original.agent_email ||
                !row.original.agent_name
              ) {
                return;
              }
              scheduleAutoOffer({
                street_address: row.original.street_address,
                zip_code: row.original.zip_code,
              });
              setLoadingOffers((old) => [...old, row.original.street_address]);
            }}
          >
            <IconCheck size="1rem" />
          </ActionIcon>
        </Tooltip>

        <Tooltip label="Hide CampaignListing" withinPortal>
          <ActionIcon
            variant="outline"
            color="red"
            loading={loadingOffers.includes(row.original.street_address)}
            onClick={() => {
              setLoadingOffers((old) => [...old, row.original.street_address]);
              rejectLead({
                campaign_id: campaign.id,
                street_address: row.original.street_address,
                zip_code: row.original.zip_code,
              });
            }}
          >
            <IconX size="1rem" />
          </ActionIcon>
        </Tooltip>

        {row.original.zpid && (
          <Tooltip label="Go to Zillow CampaignListing page" withinPortal>
            <ActionIcon
              variant="outline"
              color="#0169FE"
              onClick={() => {
                const externalUrl = `https://www.zillow.com/homedetails/1328-Sterling-Pl-APT-3L-Brooklyn-NY-11213/${row.original.zpid}_zpid/?`;
                window.open(externalUrl, '_blank')?.focus();
              }}
            >
              <IconLink size="1rem" />
            </ActionIcon>
          </Tooltip>
        )}
      </Box>
    ),
  };
};

export default Table;
