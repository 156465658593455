import { Button, Flex, Text, Paper, Popover, Box } from '@mantine/core';
import { ContactForm } from './ContactForm';
import {
  formatPhoneNumberToE164,
  formatPhoneNumberToNational,
} from 'shared/formatter';
import { useClickOutside, useDisclosure } from '@mantine/hooks';
import { CallButton } from './../Conversation/CallButton/CallButton';
import { isCallingEnabled, useLeadProfileStore } from 'fe/queries';
import { notifications } from '@mantine/notifications';

interface ContactCardProps {
  contact_id: number;
  name: string;
  email: string;
  phone: string | null;
  sms_enabled: boolean;
}

export function ContactCard({
  contact_id,
  name,
  email,
  phone,
  sms_enabled,
}: ContactCardProps) {
  const [opened, { close: closeModal, open: openModal }] = useDisclosure(false);
  const ref = useClickOutside(closeModal);
  const toggleSmsForm = useLeadProfileStore((state) => state.toggleSmsForm);
  const isEnabled = isCallingEnabled();

  const onSmsClick = () => {
    if (!phone) return;
    if (sms_enabled) {
      toggleSmsForm(phone);
    } else {
      notifications.show({
        title: 'Lead needs to contact you first',
        message:
          "This lead needs to text you first before you're able to send him a SMS. This is to comply with 10DLC regulations. You can still call this lead.",
        color: 'red',
        autoClose: 12000,
      });
    }
  };
  return (
    <Paper p="xs" radius="sm" w="100%" withBorder ref={ref}>
      <Flex direction="column" gap="xs">
        <Flex justify="space-between">
          <Text size="sm" fw="bold">
            Agent
          </Text>

          <Flex gap="xs">
            {phone && (
              <>
                <CallButton name={name} number={phone} contactId={contact_id} />
                <Button
                  compact
                  size="xs"
                  variant="default"
                  onClick={onSmsClick}
                  disabled={!isEnabled}
                >
                  SMS
                </Button>
              </>
            )}

            <Popover
              opened={opened}
              width={300}
              position="bottom"
              withArrow
              shadow="md"
            >
              <Popover.Target>
                <Button
                  onClick={() => {
                    if (!opened) {
                      openModal();
                      return;
                    }
                    closeModal();
                  }}
                  size="xs"
                  compact
                  color="gray"
                  variant={opened ? 'default' : 'subtle'}
                >
                  {opened ? 'Close' : 'Edit'}
                </Button>
              </Popover.Target>
              <Popover.Dropdown>
                <Box>
                  <ContactForm
                    id={contact_id}
                    name={name}
                    email={email}
                    phone={phone}
                    onClose={closeModal}
                  />
                </Box>
              </Popover.Dropdown>
            </Popover>
          </Flex>
        </Flex>

        <Text size="xs">{name}</Text>
        <Text size="xs">{email}</Text>
        {phone && (
          <Text
            component="a"
            href={`tel:${formatPhoneNumberToE164(phone)}`}
            size="xs"
            color="blue"
          >
            {formatPhoneNumberToNational(phone)}
          </Text>
        )}
      </Flex>
    </Paper>
  );
}
