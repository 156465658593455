import { Button, FileInput, Flex, Select, TextInput } from '@mantine/core';
import { isNotEmpty, useForm } from '@mantine/form';
import {
  FeContract,
  useCreateOrganisationContract,
  useOrganisationStore,
  useUpdateOrganisationContract,
} from 'fe/queries';
import { StandardFonts } from 'pdf-lib';

interface ContractFormProps {
  contract: FeContract | undefined;
  onSuccess: () => void;
}

export function ContractForm({ contract, onSuccess }: ContractFormProps) {
  const { organisation } = useOrganisationStore();

  if (!organisation) return null;

  const { mutateAsync: create, isPending: isCreateLoading } =
    useCreateOrganisationContract(organisation.id);
  const { mutateAsync: update, isPending: isUpdateLoading } =
    useUpdateOrganisationContract();

  const form = useForm({
    initialValues: {
      label: contract?.label || '',
      buyer_name: contract?.buyer_name || '',
      file: null as any,
      font: (contract?.font as StandardFonts) || StandardFonts.Helvetica,
    },
    validate: {
      label: isNotEmpty(),
    },
  });

  const onSubmit = form.onSubmit(async (values) => {
    if (contract) {
      await update({
        ...contract,
        contract_id: contract.id,
        ...values,
      });
    } else {
      await create(values);
    }
    onSuccess();
  });

  return (
    <div>
      <form onSubmit={onSubmit}>
        <TextInput
          mb="sm"
          withAsterisk
          label="Label"
          placeholder="Initial contract"
          {...form.getInputProps('label')}
        />

        <TextInput
          mb="sm"
          label="Buyer's name"
          placeholder="John Doe"
          {...form.getInputProps('buyer_name')}
        />

        <Select
          withinPortal
          mb="sm"
          label="Font"
          placeholder="Pick a font"
          data={Object.values(StandardFonts).map((font) => ({
            value: font,
            label: font,
          }))}
          {...form.getInputProps('font')}
        />

        {!contract && (
          <FileInput
            placeholder="Pick file"
            label="Contract file"
            withAsterisk
            {...form.getInputProps('file')}
          />
        )}

        <Flex w="100%" justify="flex-end">
          <Button
            type="submit"
            mt="md"
            loading={isCreateLoading || isUpdateLoading}
          >
            {contract ? 'Update' : 'Create'}
          </Button>
        </Flex>
      </form>
    </div>
  );
}
