import { trpc } from '../setup';

export const useStartDomainAuthentication = () => {
  const utils = trpc.useUtils();
  return trpc.sendgrid.startDomainAuthentication.useMutation({
    onSuccess: (_, params) => {
      utils.sendgrid.getDomains.invalidate({
        organization_id: params.organization_id,
      });
    },
  });
};

export const useDeleteDomain = () => {
  const utils = trpc.useUtils();
  return trpc.sendgrid.deleteDomain.useMutation({
    onSuccess: (data) => {
      utils.sendgrid.getDomains.setData(
        {
          organization_id: data.organization_id,
        },
        (previous) => previous?.filter((domain) => domain.id !== data.id),
      );
    },
  });
};

export const useValidateDomainAuthentication = () => {
  const utils = trpc.useUtils();
  return trpc.sendgrid.validateDomainAuthentication.useMutation({
    onSuccess: (data) => {
      utils.sendgrid.getDomains.setData(
        {
          organization_id: data.organization_id,
        },
        (previous) =>
          previous?.map((domain) => (domain.id === data.id ? data : domain)),
      );
      utils.sendgrid.getDomainDnsRecords.invalidate({
        domain_id: data.id,
      });
    },
  });
};

export const useDomains = trpc.sendgrid.getDomains.useQuery;

export const useDomainDnsRecords = trpc.sendgrid.getDomainDnsRecords.useQuery;
