import {
  Flex,
  Loader,
  MultiSelect,
  SegmentedControl,
  Text,
} from '@mantine/core';
import { LocationSelectorItem } from './LocationSelectorItem';
import { useLocationSelector } from './useLocationSelector';
import USAStates from './usa_states.json';
import { useState } from 'react';
import { Products } from 'shared/db';

function capitalizeFirstLetter(string: string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

interface LocationsSelectorProps {
  type: Products;
  organisationId: number;
  fetchCampaignLocations?: boolean;
  inputProps: {
    onChange: (zipCodes: number[]) => void;
    value: string[];
    error?: string;
  };
  defaultStates?: string[];
}

export function LocationsSelector({
  organisationId,
  inputProps,
  fetchCampaignLocations = false,
  type,
  defaultStates,
}: LocationsSelectorProps) {
  const [states, setStates] = useState<string[]>(defaultStates || []);
  const {
    isLoading,
    dropdownOnChange: onSelectCity,
    groupBy,
    setGroupBy,
    dropdownData,
    dropdownValue,
    numListings,
    isLoadingAmount,
  } = useLocationSelector({
    ...inputProps,
    organisationId,
    fetchCampaignLocations,
    states,
    type,
  });

  return (
    <div style={{ height: 'fit-content', flex: 1 }}>
      {!fetchCampaignLocations && (
        <MultiSelect
          data={USAStates}
          value={states}
          label="States"
          searchable
          placeholder="Pick all that you like"
          onChange={(value) => setStates(value as string[])}
        />
      )}

      <Text mt={10} fz="sm">
        Group Locations By:
      </Text>
      <SegmentedControl
        value={groupBy}
        onChange={setGroupBy}
        data={[
          { label: 'State', value: 'state' },
          { label: 'County', value: 'county' },
          { label: 'City', value: 'city' },
          { label: 'Zip Code', value: 'zip' },
        ]}
      />

      {isLoading ? (
        <div>Loading...</div>
      ) : (
        <>
          <MultiSelect
            mt={10}
            label={
              <Flex justify="space-between" w="100%" gap="xl" align="center">
                {capitalizeFirstLetter(groupBy)}

                {fetchCampaignLocations && (
                  <Flex>
                    <Text c="dimmed">
                      Listings found:{' '}
                      {isLoadingAmount ? (
                        <Loader size={10} />
                      ) : (
                        `${numListings ?? 'N/A'}*`
                      )}
                    </Text>
                  </Flex>
                )}
              </Flex>
            }
            placeholder="Pick one"
            onChange={onSelectCity}
            searchable
            clearSearchOnChange
            clearSearchOnBlur
            itemComponent={LocationSelectorItem}
            nothingFound="No options"
            data={dropdownData}
            value={dropdownValue}
            error={inputProps.error}
            filter={(value, selected, item) => {
              if (selected) {
                return false;
              }
              return (
                item?.group?.toLowerCase().includes(value?.toLowerCase()) ||
                item.value!.toLowerCase().includes(value?.toLowerCase())
              );
            }}
          />

          {fetchCampaignLocations && !isLoadingAmount && (
            <Text c="dimmed" size="xs" mt="xs">
              * Number is an approximate based on the counties you have selected
              and may not be accurate.
            </Text>
          )}
        </>
      )}
    </div>
  );
}
