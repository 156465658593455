import { Flex, Container, Loader } from '@mantine/core';

export function PageLoader() {
  return (
    <Container size="25rem" pt="xl">
      <Flex my="xl" justify="center" w="80%" align="center" h="80%">
        <Loader size="xl" />
      </Flex>
    </Container>
  );
}
