import { useDocumentTitle } from '@mantine/hooks';
import { createFileRoute } from '@tanstack/react-router';
import { TeamMembers } from 'fe/feature/organisation-settings';
import { useOrganisationStore } from 'fe/queries';

export const Route = createFileRoute('/settings/_layout/members')({
  component: RouteComponent
});

function RouteComponent() {
  useDocumentTitle('Team members - Settings - Hey Rafi');
  const { organisation } = useOrganisationStore();
  if (!organisation) return null;
  return <TeamMembers organisation={organisation} />;
}
