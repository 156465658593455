import { useDocumentTitle } from '@mantine/hooks';
import { createFileRoute } from '@tanstack/react-router';
import { ServiceAgreement } from 'fe/feature/auth';

export const Route = createFileRoute('/service-agreement')({
  component: Index
});

function Index() {
  useDocumentTitle('Service Agreement - Hey Rafi');
  return <ServiceAgreement />;
}
