import { Container, Flex } from '@mantine/core';
import { Title, Text, Anchor } from '@mantine/core';

export function TermsOfService() {
  return (
    <Container size="sm" style={{ textAlign: 'center' }}>
      <Flex direction="column" gap="md">
        <Title order={1}>Terms of Service</Title>

        <Title order={2}>1. Acceptance of Terms:</Title>
        <Text>
          By accessing or using the marketing software platform ("Platform")
          provided by Hey Rafi LLC, you agree to be bound by these Terms of
          Service ("Terms"). If you do not agree to these Terms, you may not
          access or use the Platform.
        </Text>

        <Title order={2}>2. Use of Platform:</Title>
        <Text>
          You may use the Platform solely for the purpose of facilitating
          marketing activities within the licensed counties specified in your
          agreement with Hey Rafi LLC. [found where?]
        </Text>

        <Title order={2}>3. User Responsibilities:</Title>
        <Text>
          You are responsible for maintaining the confidentiality of your
          account credentials and for all activities that occur under your
          account. You agree to provide accurate and complete information when
          registering for an account and to update your information as
          necessary.
        </Text>

        <Title order={2}>4. Charges and Payments:</Title>
        <Text>
          By using the Platform, you agree to pay all monthly charges as well as
          an onboarding expense, as specified in your agreement with Hey Rafi
          LLC. You acknowledge that you are responsible for any fees or charges
          associated with the use of the Platform.
        </Text>

        <Title order={2}>5. Changes to Charges:</Title>
        <Text>
          Hey Rafi LLC reserves the sole discretion to change charges as
          necessary. We will make our best efforts to notify you of any changes
          to charges prior to their implementation.
        </Text>

        <Title order={2}>6. Privacy Policy:</Title>
        <Text>
          Your use of the Platform is subject to our Privacy Policy, which
          outlines how we collect, use, disclose, and protect your information.
          By using the Platform, you acknowledge that you have read, understood,
          and agree to the terms of our Privacy Policy.
          <Anchor href="/privacy-policy" target="_blank">
            Privacy Policy
          </Anchor>
        </Text>

        <Title order={2}>7. Termination:</Title>
        <Text>
          We reserve the right to terminate or suspend your access to the
          Platform at any time for any violation of these Terms or non-payment
          of charges. Upon termination, your right to use the Platform will
          cease immediately.
        </Text>

        <Title order={2}>8. Limitation of Liability:</Title>
        <Text>
          Hey Rafi LLC shall not be liable for any indirect, incidental,
          special, consequential, or punitive damages arising out of or in
          connection with your use of the Platform.
        </Text>

        <Title order={2}>9. Governing Law:</Title>
        <Text>
          These Terms shall be governed by and construed in accordance with the
          laws of Texas. Any disputes arising out of or in connection with these
          Terms shall be resolved through amicable negotiation. If a resolution
          cannot be reached, the parties agree to submit to the exclusive
          jurisdiction of the courts of Texas.
        </Text>

        <Title order={2}>10. Changes to Terms:</Title>
        <Text>
          We reserve the right to update or modify these Terms at any time. We
          will notify you of any changes via email or through the Platform's
          notification system. By continuing to use the Platform after such
          changes, you agree to the updated Terms.
        </Text>

        <Title order={2}>11. Contact Us:</Title>
        <Text>
          If you have any questions or concerns about these Terms of Service,
          please contact us at
          <Anchor href="mailto:support@heyrafi.com">support@heyrafi.com</Anchor>
        </Text>

        <Text>
          By using our Platform, you acknowledge that you have read, understood,
          and agree to the terms of these Terms of Service.
        </Text>

        <Text>
          Please ensure to review and customize this Terms of Service document
          according to your specific requirements and legal advice.
        </Text>
      </Flex>
    </Container>
  );
}
